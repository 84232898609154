import React from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import Route from './Route';

import SignIn from '../pages/Sign/SignIn';
import FirstAccess from '../pages/Sign/FirstAccess';
import Recovery from '../pages/Sign/Recovery';

import Dashboard from '../pages/Dashboard';
import Checkout from '../pages/Checkout'
import Payments from '../pages/Payments';
import UserData from '../pages/Profile/UserData';
import Password from '../pages/Profile/Password';
import Detail from '../pages/Detail';

export default function Routes() {
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/primeiro-acesso" exact component={FirstAccess} />
        <Route path="/recuperar-senha" exact component={Recovery} />

        <Route path="/dashboard" exact component={Dashboard} isPrivate />
        <Route path="/checkout" exact component={Checkout} isPrivate />
        <Route path="/pagamentos" exact component={Payments} isPrivate />
        <Route path="/profile" exact component={UserData} isPrivate />
        <Route path="/password" exact component={Password} isPrivate />
        <Route path="/detail" exact component={Detail} isPrivate />
      </Switch>
    </Router>
  );
}
