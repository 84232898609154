import React, { useState, useContext } from 'react';

import AuthContext from '../../../contexts/auth';
import LayoutContext from '../../../contexts/layout';

import { errorAlert } from '../../../components/Toast';

import FormCheckUser from './FormCheckUser';
import ConfirmPhone from './ConfirmPhone';
import FormRegister from './FormRegister';

function Recovery() {
  const {
    loading,
    recoveryPassword,
    validateCodeRecovery,
    saveNewPassword,
  } = useContext(AuthContext);
  const { primaryColor, logoDefault } = useContext(LayoutContext);
  const [confirm, setConfirm] = useState(false);
  const [cpf, setCpf] = useState(null);
  const [user, setUser] = useState(null);
  const [phoneUser, setPhoneUser] = useState(null);

  const handleSubmitCheck = async data => {
    if (data.cgc_cliente === '' || data.phone === '') {
      return errorAlert(
        'CPF do Titular e Celular devem ser preenchidos corretamente',
      );
    }

    const phone = data.phone.replace(/[^0-9]+/g, '');
    const cgc_cliente = data.cgc_cliente.replace(/[^0-9]+/g, '');
    setCpf(cgc_cliente);
    setPhoneUser(phone);

    const result = await recoveryPassword(cgc_cliente, phone);

    if (result.ok) setConfirm(true);
  };

  const handleResendCode = async () => {
    await recoveryPassword(cpf, phoneUser);
  };

  const handleSubmitConfirm = async code => {
    const result = await validateCodeRecovery(code, cpf, 'recovery');

    if (result.ok) {
      setUser(result.user);
      setConfirm(false);
    }
  };

  const handleSubmitRegister = async (cgc_cliente, password) => {
    saveNewPassword(cgc_cliente, password);
  };

  return (
    <div className="col-lg-7">
      <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div>
                <div>
                  <a href="/" className="logo">
                    <img src={logoDefault} height="80" alt="logo" />
                  </a>
                </div>
                {confirm ? (
                  <ConfirmPhone
                    loading={loading}
                    onChange={handleSubmitConfirm}
                    onResendCode={handleResendCode}
                    primaryColor={primaryColor}
                  />
                ) : user ? (
                  <FormRegister
                    loading={loading}
                    user={user}
                    validateCodeRecovery={validateCodeRecovery}
                    onChange={handleSubmitRegister}
                    primaryColor={primaryColor}
                  />
                ) : (
                  <FormCheckUser
                    loading={loading}
                    onChange={handleSubmitCheck}
                    primaryColor={primaryColor}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recovery;
