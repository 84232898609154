import React, { useEffect, useState } from 'react';

import toMoney from '../../functions/toMoney';
import toLocaleCurrency from '../../functions/toLocaleCurrency';

function Summary({selected}) {
  const [totalSelected, setTotalSelected] = useState(0);

  const renderBlankSelected = () => (
    <div className="col-md-4">
      <div className="my-1 p-3 bg-body rounded shadow-sm">
        <h6 className="titulo-card  pb-2 mb-0">Detalhes do pagamento</h6>

        <div className="alinhamento-icone-vazio">
          <div className="campo-vazio">
            <div
              className="fatura-visualizar"
              id="fatura-visualizar"
              style={{ textAlign: 'center' }}
            >
              <svg
                style={{
                  width: '100%',
                  maxWidth: 110,
                  marginTop: 0,
                  marginBottom: 0,
                  marginRight: 'auto',
                  marginLeft: 'auto',
                }}
                width="110"
                height="110"
                viewBox="0 0 200 200"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M80 30C77.3478 30 74.8043 31.0536 72.9289 32.9289C71.0536 34.8043 70 37.3478 70 40V45C70 46.3261 69.4732 47.5979 68.5355 48.5355C67.5979 49.4732 66.3261 50 65 50C63.6739 50 62.4021 49.4732 61.4645 48.5355C60.5268 47.5979 60 46.3261 60 45V40C60 34.6957 62.1071 29.6086 65.8579 25.8579C69.6086 22.1071 74.6957 20 80 20H85C86.3261 20 87.5979 20.5268 88.5355 21.4645C89.4732 22.4021 90 23.6739 90 25C90 26.3261 89.4732 27.5979 88.5355 28.5355C87.5979 29.4732 86.3261 30 85 30H80Z"
                  fill="#D0D0D0"
                ></path>
                <path
                  d="M70 120C70 122.652 71.0536 125.196 72.9289 127.071C74.8043 128.946 77.3478 130 80 130H85C86.3261 130 87.5979 130.527 88.5355 131.464C89.4732 132.402 90 133.674 90 135C90 136.326 89.4732 137.598 88.5355 138.536C87.5979 139.473 86.3261 140 85 140H80C74.6957 140 69.6086 137.893 65.8579 134.142C62.1071 130.391 60 125.304 60 120V115C60 113.674 60.5268 112.402 61.4645 111.464C62.4021 110.527 63.6739 110 65 110C66.3261 110 67.5979 110.527 68.5355 111.464C69.4732 112.402 70 113.674 70 115V120Z"
                  fill="#D0D0D0"
                ></path>
                <path
                  d="M70 65C70 63.6739 69.4732 62.4021 68.5355 61.4645C67.5978 60.5268 66.3261 60 65 60C63.6739 60 62.4021 60.5268 61.4645 61.4645C60.5268 62.4021 60 63.6739 60 65V95C60 96.3261 60.5268 97.5979 61.4645 98.5355C62.4021 99.4732 63.6739 100 65 100C66.3261 100 67.5978 99.4732 68.5355 98.5355C69.4732 97.5979 70 96.3261 70 95V65Z"
                  fill="#D0D0D0"
                ></path>
                <path
                  d="M160 30C162.652 30 165.196 31.0536 167.071 32.9289C168.946 34.8043 170 37.3478 170 40V45C170 46.3261 170.527 47.5979 171.464 48.5355C172.402 49.4732 173.674 50 175 50C176.326 50 177.598 49.4732 178.536 48.5355C179.473 47.5979 180 46.3261 180 45V40C180 34.6957 177.893 29.6086 174.142 25.8579C170.391 22.1071 165.304 20 160 20H155C153.674 20 152.402 20.5268 151.464 21.4645C150.527 22.4021 150 23.6739 150 25C150 26.3261 150.527 27.5979 151.464 28.5355C152.402 29.4732 153.674 30 155 30H160Z"
                  fill="#D0D0D0"
                ></path>
                <path
                  d="M160 130C162.652 130 165.196 128.946 167.071 127.071C168.946 125.196 170 122.652 170 120V115C170 113.674 170.527 112.402 171.464 111.464C172.402 110.527 173.674 110 175 110C176.326 110 177.598 110.527 178.536 111.464C179.473 112.402 180 113.674 180 115V120C180 125.304 177.893 130.391 174.142 134.142C170.391 137.893 165.304 140 160 140H155C153.674 140 152.402 139.473 151.464 138.536C150.527 137.598 150 136.326 150 135C150 133.674 150.527 132.402 151.464 131.464C152.402 130.527 153.674 130 155 130H160Z"
                  fill="#D0D0D0"
                ></path>
                <path
                  d="M175 60C173.674 60 172.402 60.5268 171.464 61.4645C170.527 62.4021 170 63.6739 170 65V95C170 96.3261 170.527 97.5979 171.464 98.5355C172.402 99.4732 173.674 100 175 100C176.326 100 177.598 99.4732 178.536 98.5355C179.473 97.5979 180 96.3261 180 95V65C180 63.6739 179.473 62.4021 178.536 61.4645C177.598 60.5268 176.326 60 175 60Z"
                  fill="#D0D0D0"
                ></path>
                <path
                  d="M105 20C103.674 20 102.402 20.5268 101.464 21.4645C100.527 22.4021 100 23.6739 100 25C100 26.3261 100.527 27.5979 101.464 28.5355C102.402 29.4732 103.674 30 105 30H135C136.326 30 137.598 29.4732 138.536 28.5355C139.473 27.5979 140 26.3261 140 25C140 23.6739 139.473 22.4021 138.536 21.4645C137.598 20.5268 136.326 20 135 20H105Z"
                  fill="#D0D0D0"
                ></path>
                <path
                  d="M100 135C100 133.674 100.527 132.402 101.464 131.464C102.402 130.527 103.674 130 105 130H135C136.326 130 137.598 130.527 138.536 131.464C139.473 132.402 140 133.674 140 135C140 136.326 139.473 137.598 138.536 138.536C137.598 139.473 136.326 140 135 140H105C103.674 140 102.402 139.473 101.464 138.536C100.527 137.598 100 136.326 100 135Z"
                  fill="#D0D0D0"
                ></path>
                <path
                  d="M40 60H50V70H40C37.3478 70 34.8043 71.0536 32.9289 72.9289C31.0536 74.8043 30 77.3478 30 80V145C30 151.63 32.6339 157.989 37.3223 162.678C42.0107 167.366 48.3696 170 55 170H120C122.652 170 125.196 168.946 127.071 167.071C128.946 165.196 130 162.652 130 160V150H140V160C140 165.304 137.893 170.391 134.142 174.142C130.391 177.893 125.304 180 120 180H55C45.7174 180 36.815 176.313 30.2513 169.749C23.6875 163.185 20 154.283 20 145V80C20 74.6957 22.1071 69.6086 25.8579 65.8579C29.6086 62.1071 34.6957 60 40 60Z"
                  fill="#D0D0D0"
                ></path>
              </svg>
            </div>
          </div>
          <div className="text-center mt-4">
            <p className="titulo-card mb-2 card-title">Selecione uma fatura</p>
          </div>
        </div>
      </div>
    </div>
  );

  const renderSelectedSummary = () => (
    <div className="col-md-4">
      <div className="my-1 p-3 bg-body rounded shadow-sm">
        <h6 className="titulo-card pb-3 mb-0">Detalhes do pagamento</h6>

        <div className="borda-padrao titulo-valores-lista d-flex justify-content-between">
          <div className="p-2 bd-highlight">Vencimento</div>
          <div className="p-2 bd-highlight">Valor</div>
        </div>
        {selected.map((s, i) => (
          <div
            style={{
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 0,
              paddingRight: 0,
            }}
            className="borda-padrao d-flex justify-content-between"
            key={i}
          >
            <div className="align-self-center p-2 bd-highlight">
              <p className="data-valor-pagamento">{s.date}</p>
              <p className="cod-valor-pagamento">{s.chave}</p>
            </div>
            <div className="soma-valor-pagamento align-self-center p-2 bd-highlight">
              R$ {s.price}
            </div>
          </div>
        ))}
        <div className="borda-padrao titulo-valores-lista d-flex justify-content-between">
          <div className="p-2 bd-highlight">Total</div>
          <div className="p-2 bd-highlight">R$ {totalSelected}</div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (selected.length > 0) {
      let total = 0;
      selected.forEach(item => {
        const sum = toLocaleCurrency(item.price);
        total = total + sum;
      });
      setTotalSelected(toMoney(total));
    } else {
      setTotalSelected(0);
    }
  }, [selected]);

  return selected.length > 0 ? renderSelectedSummary() : renderBlankSelected();
}

export default Summary;
