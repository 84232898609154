import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { Router } from 'react-router-dom';

import { AuthProvider } from './contexts/auth';
import { LayoutProvider } from './contexts/layout';

import Routes from './routes';
import history from './services/history';
import api from './services/api';

import GlobalStyle from './styles/global';

function App() {
  const location = window.location.host;
  const [layout, setLayout] = useState(null);

  const mount = async () => {
    let data = JSON.parse(localStorage.getItem('@portal-cliente/layout'));
    if (!data) {
      const resp = await api.get(`/customer/portal/layout/${location}`);
      data = resp.data;
      localStorage.setItem('@portal-cliente/layout', JSON.stringify(data.layout));
      localStorage.setItem('@portal-cliente/companies', JSON.stringify(data.companies));
    }
    setLayout(data);
  };

  useEffect(() => {
    mount();
  }, []);

  return (
    layout && (
      <LayoutProvider>
        <AuthProvider>
          <Router history={history}>
            <ToastContainer autoClose={300} />
            <GlobalStyle />
            <Routes />
          </Router>
        </AuthProvider>
      </LayoutProvider>
    )
  );
}

export default App;
