import React, { useState, useEffect } from 'react';

function FormRegister({ loading, primaryColor, onChange }) {
  // const cpf = user
  const [data, setData] = useState({
    password: '',
    passwordConfirm: '',
  });
  const [errorPass, setErrorPass] = useState(false);
  const [errorConfirm, setErrorConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfrmPassword, setShowConfirmPassword] = useState(false);

  const handleChange = event => {
    setData({ ...data, [event.target.id]: event.target.value });
  };

  const handleChangeRegister = async () => {
    if (!errorPass && !errorConfirm) onChange(data.password);
  };

  useEffect(() => {
    if (data.password.length > 1 && data.password.length < 6) {
      setErrorPass(true);
    } else {
      setErrorPass(false);
    }
    if (data.password !== data.passwordConfirm && data.passwordConfirm > 0) {
      setErrorConfirm(true);
    } else {
      setErrorConfirm(false);
    }
  }, [data]);

  return (
    <form className="form-horizontal" onSubmit={handleChangeRegister}>
      <div className="form-group auth-form-group-custom mb-4">
        <label htmlFor="password">Senha</label>
        <input
          id="password"
          placeholder="DIGITE SUA SENHA"
          type={showPassword ? 'text' : 'password'}
          value={data.password}
          onChange={handleChange}
          className={
            errorPass
              ? 'form-control text-input error'
              : 'form-control text-input'
          }
        />
        {showPassword ? (
          <i
            className="ri-eye-off-line auti-custom-input-icon"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowPassword(!showPassword)}
          ></i>
        ) : (
          <i
            className="ri-eye-line auti-custom-input-icon"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowPassword(!showPassword)}
          ></i>
        )}
        {errorPass && (
          <div className="input-feedback error">
            A senha deve ter no mínimo 6 digitos
          </div>
        )}
      </div>
      <div className="form-group auth-form-group-custom mb-4">
        <label htmlFor="confirmPassword">Confirmação de senha</label>
        <input
          id="passwordConfirm"
          placeholder="CONFIRME SUA SENHA"
          type={showConfrmPassword ? 'text' : 'password'}
          value={data.passwordConfirm}
          onChange={handleChange}
          className={
            errorConfirm
              ? 'form-control text-input error'
              : 'form-control text-input'
          }
        />
        {showConfrmPassword ? (
          <i
            className="ri-eye-off-line auti-custom-input-icon"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowConfirmPassword(!showConfrmPassword)}
          ></i>
        ) : (
          <i
            className="ri-eye-line auti-custom-input-icon"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowConfirmPassword(!showConfrmPassword)}
          ></i>
        )}
        {errorConfirm && (
          <div className="input-feedback error">As senhas não correspondem</div>
        )}
      </div>
      <div className="mt-4 text-center">
        <a
          href="/"
          style={{
            padding: 13,
            fontSize: 18,
            fontWeight: 600,
            float: 'left',
            width: '45%',
            paddingLeft: 0,
          }}
          className="btn btn-outline-primary"
          onClick={sessionStorage.removeItem('@portal-cliente/session')}
        >
          Sair
        </a>
        <button
          style={{
            padding: 13,
            fontSize: 18,
            fontWeight: 600,
            float: 'right',
            width: '45%',
            backgroundColor: primaryColor,
            filter: loading ? 'opacity(30%)' : '',
          }}
          className="btn btn-primary"
          type="submit"
          disabled={loading}
          onClick={handleChangeRegister}
        >
          {loading ? 'Carregando...' : 'Salvar'}
        </button>
      </div>
    </form>
  );
}

export default FormRegister;
