import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  IconButton,
  Tooltip,
  SwipeableDrawer,
} from '@material-ui/core/';
import { AccountCircle } from '@material-ui/icons';

import AuthContext from '../../../contexts/auth';
import LayoutContext from '../../../contexts/layout';

export default function DefaultLayout({ children }) {
  const pathname = window.location.pathname;
  const { signOut, user } = useContext(AuthContext);
  const {
    primaryColor,
    logoDefault,
    logoLight,
    footerBanner,
    logoDark,
    logoCircle,
    address,
    contact,
    description,
  } = useContext(LayoutContext);
  const [drawer, setDrawer] = useState(false);

  const handleProfileMenuOpen = open => event => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setDrawer(open);
  };

  const handleLogOut = () => {
    signOut(true);
  };

  const list = () => (
    <div
      style={{ width: 400 }}
      role="presentation"
      onClick={handleProfileMenuOpen(false)}
      onKeyDown={handleProfileMenuOpen(false)}
    >
      <div className="float-right" style={{ margin: 10 }}>
        <button
          type="button"
          className="close"
          aria-label="Close"
          style={{ color: '#fff' }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        className="cor-global-primaria"
        tabIndex="-1"
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        style={{ margin: 30 }}
      >
        <div className="offcanvas-body">
          <div className="avatar-contrato-menu" style={{ marginTop: 50 }}>
            <div className="cor-global-secundaria fundo-logo-offcanvas">
              <img
                className="avatar-imagem-menu"
                src={logoCircle}
                alt="icone da logo"
                style={{ width: 100 }}
              />
            </div>
            <p className="text-white nome-menu">{user.name}</p>
          </div>
          <div className="action-bar-botao-Box menu-offcanvas-portal">
            <a href="/" id="" className="action-bar-botao">
              <i className="action-bar-botao-icone ri-home-4-line"></i> Tela
              inicial
            </a>
            <a href="/profile" id="" className="action-bar-botao">
              <i className="action-bar-botao-icone ri-user-line"></i> Dados do
              usuário
            </a>
            <a href="/password" id="" className="action-bar-botao">
              <i className="action-bar-botao-icone ri-lock-password-fill"></i>{' '}
              Senha
            </a>
            {/* <a href="/termos-de-uso" id="" className="action-bar-botao">
              <i className="action-bar-botao-icone ri-file-list-3-line"></i>{' '}
              Termos de uso
            </a> */}
            <a
              href="/"
              id=""
              className="action-bar-botao"
              onClick={handleLogOut}
            >
              <i className="action-bar-botao-icone ri-logout-box-r-line"></i>
              Sair
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  const renderMenuHome = () => (
    <ul className="nav menu-abas">
      <Link
        to="/dashboard"
        style={{
          textDecoration:
            pathname !== '/pagamentos' && pathname !== '/detail'
              ? 'underline'
              : 'none',
          color:
            pathname !== '/pagamentos' && pathname !== '/detail'
              ? '#ffff'
              : '#ffffff7a',
          marginLeft: 15,
          marginRight: 15,
          textUnderlineOffset: 29,
        }}
      >
        Vencimentos
      </Link>
      <Link
        to="/pagamentos"
        style={{
          textDecoration: pathname === '/pagamentos' ? 'underline' : 'none',
          color: pathname === '/pagamentos' ? '#ffff' : '#ffffff7a',
          marginLeft: 15,
          marginRight: 15,
          textUnderlineOffset: 29,
        }}
      >
        Pagamentos
      </Link>
      {pathname === '/detail' && (
        <p
          to="#"
          style={{
            textDecoration: pathname === '/detail' ? 'underline' : 'none',
            color: pathname === '/detail' ? '#ffff' : '#ffffff7a',
            margin: 0,
            marginLeft: 15,
            marginRight: 15,
            textUnderlineOffset: 29,
          }}
        >
          Comprovante
        </p>
      )}
    </ul>
  );

  const renderMenuUser = () => (
    <ul className="nav menu-abas">
      <Link
        to="/profile"
        style={{
          textDecoration: pathname === '/profile' ? 'underline' : 'none',
          color: pathname === '/profile' ? '#ffff' : '#ffffff7a',
          marginLeft: 15,
          marginRight: 15,
          textUnderlineOffset: 29,
        }}
      >
        Usuário
      </Link>
      <Link
        to="/password"
        style={{
          textDecoration: pathname === '/password' ? 'underline' : 'none',
          color: pathname === '/password' ? '#ffff' : '#ffffff7a',
          marginLeft: 15,
          marginRight: 15,
          textUnderlineOffset: 29,
        }}
      >
        Senha
      </Link>
    </ul>
  );

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={drawer}
        onClose={handleProfileMenuOpen(false)}
        onOpen={handleProfileMenuOpen(true)}
      >
        {list()}
      </SwipeableDrawer>
      <AppBar position="static" style={{ backgroundColor: primaryColor }}>
        <nav className="cor-global-primaria navbar navbar-expand-md navbar-dark fixed-top bg-dark">
          <div className="container-fluid">
            <a
              className="cor-global-secundaria fundo-logo navbar-brand"
              href="/"
            >
              <img
                className="logo-principal-mobile"
                src={logoDefault}
                alt="icone da logomarca"
              />
            </a>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls="primary-search-account-menu"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen(true)}
              color="inherit"
              style={{ marginRight: 0 }}
            >
              <AccountCircle />
            </IconButton>
          </div>
        </nav>
        <div className="linha-tabs-menu cor-global-primaria nav-scroller bg-body shadow-sm">
          <nav className="nav nav-underline" aria-label="Secondary navigation">
            <a
              className="cor-global-secundaria fundo-logo efeito-desktop navbar-brand"
              href="/dashboard"
            >
              <img className="logo-principal" src={logoLight} alt="Logomarca" />
            </a>
            {pathname === '/profile' || pathname === '/password'
              ? renderMenuUser()
              : renderMenuHome()}
            <div className="efeito-desktop icones-usuario-menu-desktop icone-usuario">
              <span className="linha-divisoria-menu"></span>
              <Tooltip title="Perfil">
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="primary-search-account-menu"
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen(true)}
                  color="inherit"
                  style={{ marginRight: 0 }}
                >
                  <AccountCircle />
                </IconButton>
              </Tooltip>
            </div>
          </nav>
        </div>
      </AppBar>
      <main className="alinhamento-topo-padrao container-fluid">
        <div className="tab-content" id="pills-tabContent">
          {children}
        </div>
        <div className="">
          <img className="banner-rodape" src={footerBanner} alt="Banner" />
        </div>
        <div className="linha-rodape"></div>
        <div className="rodape-cliente container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className="">
                <a href="/" className="">
                  <img style={{ marginTop: -15 }} width="150" src={logoDark} />
                </a>
                <div className="rodape-texto">
                  {address?.length &&
                    address.map((item, index) => (
                      <div className="d-flex" key={index}>
                        <i
                          style={{ fontSize: 18, marginRight: 10 }}
                          className="ri-home-2-fill"
                        ></i>
                        {item}
                        <br />
                        <br />
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="">
                <p className="titulo-rodape">Informações</p>
                <div className="rodape-texto">
                  <p className="">{description}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="">
                <p className="titulo-rodape">Contato</p>
                <ul className="list-unstyled f_list">
                  {contact && contact.landline && (
                    <li>
                      <a className="texto-rodape-link" href="#">
                        <i className="ri-phone-fill"></i> {contact.landline}
                      </a>
                    </li>
                  )}
                  {contact && contact.mobile && (
                    <li>
                      <a className="texto-rodape-link" href="#">
                        <i className="ri-phone-fill"></i> {contact.landline}
                      </a>
                    </li>
                  )}
                  {contact && contact.whatsapp && (
                    <li>
                      <a
                        className="texto-rodape-link"
                        href={`https://api.whatsapp.com/send?l=pt-BR&phone=55${contact.whatsapp.replace(
                          /[^0-9]/gi,
                          '',
                        )}`}
                        target="_blank"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Fale com nossos atendentes"
                      >
                        <i className="ri-whatsapp-fill"></i> {contact.whatsapp}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div style={{ padding: 10 }}></div>
      </main>
    </>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
