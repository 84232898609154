import { createGlobalStyle } from 'styled-components';

import 'react-toastify/dist/ReactToastify.css';
import 'react-perfect-scrollbar/dist/css/styles.css';

const layout = JSON.parse(localStorage.getItem('@portal-cliente/layout'));

export default createGlobalStyle`
  body{
		font-family: 'Montserrat', sans-serif!important;
    background: #E5E5E5;
	}
	.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
		color: #343a40;
		font-family: 'Montserrat', sans-serif!important;
	}

   .font-size-30 {
		font-size: 30px!important;
		font-weight: 600;
	}
	  td, th {
		vertical-align: middle;
	  }

	.btn-primary {
		color: #fff;
		font-family: 'Montserrat', sans-serif;
		background-color: ${layout ? layout.primaryColor : '#FFFFFF'};
		border-style: solid;
		border-width: 2px;
		border-color: #00000000;
	}

	.btn-primary-pay {
		margin: 0px 10px
	}

	tr {
		font-size: 14px;
	}
	.btn-outline-padrao {
		border-color: #a9a9a9;
		border-style: solid;
		border-width: 1px;
		padding: 10px 15px;
		border-radius: 10px;
		color: #5f5f5f;
		display: inline;
		font-size: 15px;
	}
	.btn-outline-padrao:hover {
		background-color: #cecece;
		color: #3a3a3a;
		transition: 0.3s;
	}
	.btn-outline-padrao:active {
		background-color: #cecece;
		color: #3a3a3a;
		transition: 0.3;
	}
	.btn-outline-padrao:focus {
		background-color: #cecece;
		color: #3a3a3a;
		transition: 0.3;
	}
	.btn-primary:hover {
		color: #fff;
		background-color: ${layout ? layout.primaryColor : '#FFFFFF'};
		border-color:${layout ? layout.primaryColor : '#FFFFFF'};
    opacity: 0.8
	}
	.btn-check:focus+.btn-primary, .btn-primary:focus {
		color: #fff;
		background-color: #8253c6;
		border-color: #8253c6;
		box-shadow: 0 0 0 0.25rem rgb(167 125 229 / 51%);
	}
	.btn-check:active+.btn-primary, .btn-check:checked+.btn-primary, .btn-primary.active, .btn-primary:active, .show>.btn-primary.dropdown-toggle {
		color: #fff;
		background-color: #8253c6;
		border-color: #8253c6;
	}
	.btn-check:active+.btn-primary:focus, .btn-check:checked+.btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show>.btn-primary.dropdown-toggle:focus {
		box-shadow: 0 0 0 0.25rem rgb(130 83 198 / 40%);
	}

	.btn-outline-primary {
		color: ${layout ? layout.primaryColor : '#FFFFFF'};
		border-color: ${layout ? layout.primaryColor : '#FFFFFF'};
	}
	.btn-outline-primary:hover {
		color: #fff;
		background-color: ${layout ? layout.primaryColor : '#FFFFFF'};
		border-color: ${layout ? layout.primaryColor : '#FFFFFF'};
    opacity: 0.8
	}
	.btn-check:active+.btn-outline-primary:focus, .btn-check:checked+.btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
		box-shadow: 0 0 0 0.25rem rgb(109 88 164 / 41%);
	}

	.nav {
		display: flex;
		flex-wrap: initial;
	}
	tr {
		vertical-align: middle;
	}
	.user-icone-menu-bg {
		background-image: url(assets/imagens/user.svg);
		background-repeat: no-repeat;
		background-size: 30px;
		padding: 15px;
		margin-top: -10px!important;
		margin-bottom: -10px!important;
		border: none;
		background-position-y: 12px;
		background-position-x: 15px;
		padding-left: 30px;
		padding-right: 34px;
		background-color: #0000;

	}
	.user-icone-menu-bg-mobile {
		background-image: url(assets/imagens/user.svg);
		background-repeat: no-repeat;
		background-size: 30px;
		padding: 15px;
		background-color: none;
		margin-top: 5px!important;
		background-color: #604a99;
		border: none;

	}
	.borda-padrao{
		border-bottom: #EFEFEF;
		border-bottom-style: solid;
		border-bottom-width: 1px;
	}
	.fundo-logo {
		margin-top: -12px;
		margin-bottom: -17px;
		padding: 15px 15px 9px 10px;
	}

	.nome-menu {
		text-align: center;
		font-size: 20px;
		font-weight: 600;
		padding-top: 25px;
		margin-bottom: 0px;
	}
	.contrato-menu {
		text-align: center;
		font-size: 16px;
		padding-top: 0px;
		color: #ffffff85!important;
	}
	.avatar-imagem-menu{
	  width: 100%;
		max-width: 100px;
		margin: 0 auto;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		position: relative;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.fundo-logo-offcanvas{
		background: #000;
		max-width: 107px;
		margin: 0 auto;
		padding: 29px;
		border-radius: 100%;
	}

	.alinhamento-topo-padrao{
		margin-top: 25px;
		margin-bottom: 25px;
	}
	.menu-offcanvas-portal{
		display: flex;
		flex-direction: column;

	}
	.linha-divisoria-menu{
		border-style: solid;
		border-color: #ffffff40;
		border-width: 1px;
		margin-right: 10px;

	}
	.icones-usuario-menu-desktop{
		display: flex;
	}
	.menu-abas{
		margin: auto;
		justify-content: flex-start;
		width: 100%;
		margin-left: 40px;
	}
	.titulo-rodape {
		font-size: 18px;
		font-weight: 600;
	}
	.rodape-texto {
		font-size: 15px;
		max-width: 390px;
		color: #717171;
	}
	.texto-rodape-link{
		text-decoration: none;
		color: #717171;
	}
	.texto-rodape-link:hover{
		text-decoration: none;
		color: #333333;
		font-weight: 500;
	}
	.texto-rodape-link:active{
		text-decoration: none;
		color: #333333;
		font-weight: 500;
	}
	.texto-rodape-link:focus{
		text-decoration: none;
		color: #333333;
		font-weight: 500;
	}
	.list-unstyled {
		line-height: 30px;
		font-size: 15px;
	}
	.linha-tabs-menu{
		border-bottom-style: solid;
		border-bottom-color: #ffffff;
		border-bottom-width: 5px;
		padding: 13px 0px;

	}
	.cor-global-primaria{
		background-color:${layout ? layout.primaryColor : '#FFFFFF'}!important;
		color:#604A99!important;

	}
	.cor-global-secundaria{
		background-color:${layout ? layout.primaryColor : '#FFFFFF'};!important;

	}
	.logo-principal {
		width: 100%;
		max-width: 160px;
		max-height: 350px;
	}
	.logo-principal-mobile {
		width: 32px;
	}
	.titulo-tabela{
		font-weight: 600;
		padding: 12px 0px;
		background-color: #ffffffb8;

	}

	.active-pagamento {
		display: block;
		margin: 0px -15px;
		padding: 0px 15px;
	}

	.pagamento-selecionado-tabela {
		margin: 0px -15px;
		padding: 20px 15px;
	}


	.descricao-tabela {
		padding: 20px 15px;
		border-style: solid;
		border-width: 1px;
		border-left-style: none;
		border-right-style: none;
		border-color: #e3e3e359;
		margin: 0px -15px;
		transition: 0.3s;
		cursor: pointer;
	}
	.descricao-tabela:hover {
		border-color: #e3e3e3;
		background-color: #f5f5f5;
		transition: 0.3s;
	}
	.descricao-tabela:active {
		border-color: #e3e3e3;
		background-color: #f5f5f5;
		transition: 0.3s;
	}
	.descricao-tabela:focus {
		border-color: #e3e3e3;
		background-color: #f5f5f5;
		transition: 0.3s;
	}


	.banner-rodape {
		width: 100%;
		padding: 15px 10%;
	}

	.fixed-top {
		position: fixed;
		right: 0;
		left: 0;
		z-index: 1030;
		bottom: 0;
		top: auto;
		display: none;
	}
	.offcanvas-backdrop::before {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1030;
	}
	i.cor-arrow-menu.ri-arrow-down-s-line {
		font-size: 25px;
		color: #ffff;
		padding-right: 10px;
		margin-top: 3px;
		z-index: 0;
		margin-left: -10px;
	}
		.icone-usuario {
				background-color: #0000;
				-webkit-transition: background-color 0.3s ease-out;
				-moz-transition: background-color 0.3s ease-out;
				-o-transition: background-color 0.3s ease-out;
				transition: background-color 0.3s ease-out;
				margin-top: -10px;
				margin-bottom: -10px;
				padding-top: 10px;
				padding-bottom: 10px;
		}
		.icone-usuario:hover {
		background-color: #ffffff2e;
		}
		.icone-usuario:active{
		background-color: #ffffff2e;
		}
		.tabs-portal-cliente {
			color: #fff;
			background: 0 0;
			border: 0;
			cursor: pointer;
			display: block;
			padding: .5rem 1rem;
			text-decoration: none;
			text-transform: none;
		}
		.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
			color: #fff!important;
			background-color: #ffffff00;
			border-bottom-style: solid;
			border-bottom-width: 5px;
			border-radius: 0px;
			font-weight: 600;
		}

		button#vencimentos-tab {
			margin-top: -17px;
			padding-top: 18px;
			padding-bottom: 13px;
			margin-bottom: -18px;
			padding-right: 20px;
			padding-left: 20px;
			color: #ffffff7a;

		}
		button#pagamentos-tab{
			margin-top: -17px;
			padding-top: 18px;
			padding-bottom: 13px;
			margin-bottom: -18px;
			padding-right: 20px;
			padding-left: 20px;
			color: #ffffff7a;

		}
		button#senha-tab{
			margin-top: -17px;
			padding-top: 18px;
			padding-bottom: 13px;
			margin-bottom: -18px;
			padding-right: 20px;
			padding-left: 20px;
			color: #ffffff7a;

		}
		button#usuario-tab{
			margin-top: -17px;
			padding-top: 18px;
			padding-bottom: 13px;
			margin-bottom: -18px;
			padding-right: 20px;
			padding-left: 20px;
			color: #ffffff7a;

		}
		button#comprovante-tab{
			margin-top: -17px;
			padding-top: 18px;
			padding-bottom: 13px;
			margin-bottom: -18px;
			padding-right: 20px;
			padding-left: 20px;
			color: #ffffff7a;

		}
		.authentication-bg {
			background-image: url(${layout ? layout.loginBanner : '' });
			height: 100vh;
			background-size: cover;
			margin-left: -12px;
			background-position: center;
		}
		.auth-form-group-custom {
			position: relative;
		}
		.form-control:focus {
			color: #505d69;
			background-color: #fff;
			border-color: #b1bbc4;
			outline: 0;
			-webkit-box-shadow: none;
			box-shadow: none;
		}
		.auth-form-group-custom .form-control {
			height: 60px;
			padding-top: 28px;
			padding-left: 20px;
			font-size: 15px;
		}
		.auth-form-group-custom label {
			position: absolute;
			top: 7px;
			left: 20px;
			font-size: 12px;
		}
		.action-bar-botao {
			display: flex;
			outline: none;
			min-height: 50px;
			align-items: center;
			transition-duration: .2s;
			padding: 0 10px;
			color: #ffffff99;
			text-decoration: none;
			cursor: pointer;
			margin: 0px -16px;
		}
		.action-bar-botao:hover {
			background: #ffffff14;
			color: #ffffff99;
		}
		.action-bar-botao:focus {
			background: #ffffff14;
			color: #ffffff99;
		}
		.action-bar-botao:active {
			background: #ffffff14;
			color: #ffffff99;
		}

		.action-bar-botao-icone {
			width: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 22px;
		}
		.action-bar-botao-Box {
			display: flex;
			flex-direction: column;
			margin-top: 40px;
		}
		label {
			font-weight: 600;
		}
		.auth-form-group-custom .auti-custom-input-icon {
			position: absolute;
			top: 50%;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			left: 88%;
			font-size: 24px;
			color: #9e9e9e;
		}
		.custom-control {
			position: relative;
			display: block;
			min-height: 1.35rem;
		}
		.alinhamento-icone-vazio {
			margin: 10.3em 0px 9.2em;
		}

		.detalhar-desktop{
			display: block;
		}
		.detalhar-mobile{
			display: none;
		}

		.fatura-visualizar{
			text-align: center;

		}
		.titulo-card {
			font-size: 18px;
			margin: 0 0 7px 0;
			font-weight: 300;
			color: #767676;
		}
		input[type=checkbox], input[type=radio] {
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			padding: 0;
		}
		button, input {
			overflow: visible;
		}
		.btn-block {
			display: block;
			width: 100%;
		}
		.custom-control-label {
			position: relative;
			margin-bottom: 0;
			vertical-align: top;
		}
		.custom-control-input {

			left: 0;
			z-index: -1;
			width: 1rem;
			height: 1.175rem;
		}
		.form-control {
			display: block;
			width: 100%;
			height: calc(1.5em + .94rem + 2px);
			padding: .47rem .75rem;
			font-size: .9rem;
			font-weight: 400;
			line-height: 1.5;
			color: #505d69;
			background-color: #fff;
			background-clip: padding-box;
			border: 1px solid #ced4da;
			border-radius: .25rem;
			-webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
		}
		.badge-soft-success {
			color: #5F97EA;
			background-color: rgba(28,187,140,.18);
			font-weight: 400
		}
		.badge-soft-danger {
			color: #ff3d60;
			background-color: rgba(255,61,96,.18);
			font-weight: 400
		}
		.table>:not(caption)>*>* {
			padding: 1.3rem .5rem;
			border-color: #e7e7e7;
		}
		.table>thead {
			vertical-align: bottom;
			color: #b5b5b5;
		}
		.pills-horas{
			padding: 5px;
			background: #ECECEC;
			color: #767676;
			border-radius: 5px;
			margin-left: 15px;
			display: initial;

		}
		.cor-titulo-tabela{
			color: #333;
		    border-color: #bbbbbb!important;

		}
		.table{
			color: #6d6d6d;
			font-family: 'Montserrat', sans-serif!important;
		}
		.table-hover>tbody>tr {
			transition: 1.0s;
		}
		.table-hover>tbody>tr:hover {
			--bs-table-accent-bg: rgb(0 0 0 / 2%);
			color: var(--bs-table-hover-color);
			cursor: pointer;
			transition: 0.3s;
		}

		.alinhamento-formulario-nome-e-senha {
			max-width: 500px;
			margin: 0 auto;
			padding-top: 40px;
			padding-bottom: 40px;
		}

		.linha-rodape {
			padding: 30px 0px 0px;
			border-top-color: #dcdcdc;
			border-top-style: solid;
			border-width: 1px;
			margin: 0 auto;
			margin-top: 30px;
		}
		.filtrar-por {
			font-size: 13px;
			font-weight: 600;
			padding: 3px 10px 0px 0px;
		}
		.titulo-elementos {
			margin-bottom: 25px;
		}
		.btn-filtro-card {
			border-style: solid;
			border-width: 1px;
			border-color: #D8D8D8;
			border-radius: 200px;
			color: #767676;
			padding: 4px 12px;
			font-size: 12px;
			margin: 0px 2px;
			cursor: pointer;
		}
		.btn-filtro-card-active {
			background-color: #e9e9e9;
			transition: 0.3s;
			color: #333333;
			cursor: pointer;
		}
		.btn-filtro-card:hover {
			background-color: #e9e9e9;
			transition: 0.3s;
			color: #333333;
			cursor: pointer;
		}
		.btn-filtro-card:active {
			background-color: #e9e9e9;
			transition: 0.3s;
			color: #333333;
			cursor: pointer;
		}
		.btn-filtro-card:focus {
			background-color: #e9e9e9;
			transition: 0.3s;
			color: #333333;
			cursor: pointer;
		}
		.cor-em-aberto-numero{
			color: #5F97EA;
			font-weight: 500;
		}
		.cor-em-atraso-numero{
			color: #F88374;
			font-weight: 500;
		}

		.paginacao-lista {
			background: #e7e7e7;
			border-radius: 100%;
			padding: 10px 19px;
		}
		.rodape-lista-paginacao{
			margin: 25px 0px 10px 0px;
		}
		.seta-paginacao{
			font-size: 30px;
			color: #c8c8c8;
			cursor: pointer;
		}
		.seta-paginacao:hover {
			color: #7a7a7a;
		}
		.seta-paginacao:focus {
			color: #7a7a7a;
		}
		.seta-paginacao:active {
			color: #7a7a7a;
		}


		/*Flex para rodape*/
			.parent {
				display: flex;
			}

			.left,
			.right {
				flex: 1;
			}

			.parent {
				padding: 20px 0px 0px 0px;
			}
			.left,
			.right {
				padding: 3px;
			}
			.center {
				margin: 0 3px;
				padding: 3px;
			}
			.lista-comprovante {
				list-style-type: none;
				line-height: 30px;
				padding: 0;
				font-size: 15px;
				color: #767676;
			}
			.lista-comprovante-2 {
				color: #767676;
				font-weight: 400;
			}
			.card-comprovante {
				width: 100%;
				max-width: 500px;
				padding: 20px 22px!important;
			}
		/*/Flex para rodape*/

		.texto-contagem-rodape-lista {
			font-size: 13px;
			color: #adadad;
		}

/*btn material*/
	.ripple {
	  background-position: center;
	  transition: background 0.8s;
	}
	.ripple:hover {
	  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
	}
	.ripple:active {
	  background-color: #6eb9f7;
	  background-size: 100%;
	  transition: background 0s;
	}
	label:checked:before{
		background-color:green;
		}
	.selecionados-lista {
		font-size: 20px;
		font-weight: 500;
	}
	.cor-global-primaria.selecionados-lista {
		background-color: #ffffff00!important;
	}
	.btn-pagar-agora {
		color: #ffffff!important;
		font-weight: 300;
		font-size: 15px;
		padding: 8px 15px;
		border-radius: 5px;
		cursor: pointer;
		transition: 0.3s;
	}
	.btn-pagar-agora:hover {
		color: #ffffff!important;
		font-weight: 300;
		font-size: 15px;
		padding: 8px 15px;
		border-radius: 5px;
		cursor: pointer;
		transition: 0.3s;
		opacity: 0.7;
	}
	.btn-pagar-agora:focus {
		color: #ffffff!important;
		font-weight: 300;
		font-size: 15px;
		padding: 8px 15px;
		border-radius: 5px;
		cursor: pointer;
		transition: 0.3s;
		opacity: 0.7;
	}
	.titulo-valores-lista {
		font-weight: 600;
		padding: 30px 0px 10px;
	}
	.imagem-modal-pagamento {
		max-width: 300px;
		margin: 0 auto;
		display: block;
		margin-top: -20px;
	}
	.texto-modal-pagamento {
		text-align: center;
		font-weight: 600;
		font-size: 23px;
		padding: 30px 0px;
		max-width: 410px;
		margin: 0 auto;
	}
	.modal-content {
		border-radius: 0.7rem!important;
	}
	.caixa-valor-total-lista {
		background: #F5F3F6;
		border-color: #E7E7E7;
		border-width: 2px;
		border-style: solid;
		border-radius: 8px;
		padding: 15px 5px;
		margin-top: 20px;
	}
	.valor-total-somadolista{
		background: #fff0!important;
		font-weight: 600;
		font-size: 20px;
	}
	.data-valor-pagamento {
		font-weight: 500;
		font-size: 15px;
		margin-bottom: 0;
	}
	.cod-valor-pagamento {
		font-size: 12px;
		color: #adadad;
		margin-bottom: 0;
	}
	.soma-valor-pagamento {
		font-weight: 500;
		font-size: 15px;
		margin-bottom: 0;
	}











/*Medias Query*/

      @media (max-width: 1024px) {
		  .p-4 {
			padding: 1.5rem!important;
		}
		  .authentication-bg {
			display: none;
		}
			.alinhamento-topo-padrao{
			margin-top: 10px;
			margin-bottom: 10px;
			padding: 0px 0px;

		}
		label {
			display: block!important;
		}
		.alinhamento-formulario-nome-e-senha {
			max-width: 500px;
			margin: 0 auto;
			padding-top: 10px;
			padding-bottom: 10px;
		}
		.fixed-top {
			position: fixed;
			right: 0;
			left: 0;
			z-index: 1030;
			bottom: 0;
			top: auto;
			display: block;
		}
		.efeito-desktop{
			display: none;
		}

		.fundo-logo{
			margin-top: -7px;
			margin-bottom: -7px;
			padding: 10px 15px 10px 15px;
			margin-left: -12px;
		}
		.icone-usuario {
			background-color: #0000;
			-webkit-transition: background-color 0.3s ease-out;
			-moz-transition: background-color 0.3s ease-out;
			-o-transition: background-color 0.3s ease-out;
			transition: background-color 0.3s ease-out;
			margin-bottom: 0px;
			padding-top: 5px;
			padding-bottom: 28px;
		}
		.menu-abas{
			margin: auto;
			justify-content: flex-start;
			width: 100%;
			margin-left: 0px;
			font-size: 12px;
		}
		.linha-tabs-menu {
			padding: 18px 0px;
		}
		.banner-rodape {
			width: 100%;
			padding: 15px 5%;
		}
		.rodape-cliente {
			margin-bottom: 15px;
			padding: 0px 30px;
		}
		.titulo-card {
			font-size: 18px;
			margin: 0 0 7px 0;
			font-weight: 300;
			color: #767676;
			padding-bottom: 25px!important;
		}
		.row>* {
			flex-shrink: 0;
			width: 100%;
			max-width: 100%;
			padding-right: initial;
			padding-left: initial!important;
			margin-top: var(--bs-gutter-y);
		}
		.btn-outline-padrao {
			border-color: #a9a9a9;
			border-style: solid;
			border-width: 1px;
			padding: 10px 15px;
			border-radius: 10px;
			color: #5f5f5f;
			display: inline;
			font-size: 12px;
		}
		.left {
			display: none;
		}
		.titulo-elementos {
			margin-bottom: 25px;
			flex-direction: column;
		}
		.filtrar-por-mobile {
			display: flex!important;
			margin-left: initial!important;
		}
		.alinhamento-icone-vazio {
			margin: 2.4em 0px 2.7em;
		}
		.selecionados-lista {
			font-size: 18px;
			font-weight: 500;
		}
		.card-comprovante {
			width: 100%;
			max-width: 100%;
			padding: 1rem!important;
		}
		.detalhar-desktop{
			display: none;
		}
		.detalhar-mobile{
			display: block;
		}
		.pills-horas{
			margin-left: 5px;
		}

  }

  /* custom bootstrap */
  .text-end {
    text-align: right!important;
  }

  .form-check-input:checked {
    background-color: ${layout ? layout.primaryColor : '#FFFFFF'};
    border-color: ${layout ? layout.primaryColor : '#FFFFFF'};
  }

  input.error {
    border-color: red;
  }

  select.error {
    border-color: red;
  }

  div.error {
    color: red;
  }

  .btn-primary.disabled, .btn-primary:disabled {
    background-color: ${layout ? layout.primaryColor : '#FFFFFF'};
    border-color: ${layout ? layout.primaryColor : '#FFFFFF'};
  }

  .authentication-bg {
    height: 100vh;
    background-size: cover;
    background-position: center;
    position: fixed;
    left: 0;
    max-width: 41.66667%;
    width: 100%;
  }

  .vcode-input {
    width: 60px;
    height: 60px;
    text-align: center;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #a9a9a9;
    margin: 0px 15px;
    font-size: 30px;
    font-weight: 700;
  }

  div#vcode {
    padding: 50px 0px;
    text-align: center;
  }

  .bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }

  label {
    font-weight: initial;
    display: block!important;
  }

  .ms-auto {
    margin-left: auto!important;
  }

  /* custom Material UI */

  .cor-global-primaria {
    color: #FFF!important;
  }

  .PrivateTabIndicator-colorSecondary-3 {
    margin-top: 20px;
    background-color: #FFF;
  }

  .MuiDrawer-paperAnchorRight {
    background-color: ${layout ? layout.primaryColor : '#FFFFFF'}!important;
  }

  .MuiDrawer-paper {
    overflow-y: hidden;
    overflow-x: hidden;
  }

  /* custom Medias Query*/

  @media (max-width: 1024px) {
    .container-fluid{
      padding-left: 1rem;
    }
  }

  @media (min-width: 768px) {
    .bd-placeholder-img-lg {
      font-size: 3.5rem;
    }
  }

  @media (max-width: 1025px){
    .vcode-input {
      margin: 0px 2%;
    }
  }
`;
