import React, { useState, useEffect, useContext } from 'react';
import Cards from 'react-credit-cards';
import InputMask from 'react-input-mask';
import { osName, osVersion } from 'react-device-detect';
import CryptoJS from 'crypto-js';

import Summary from '../../components/Summary';

import toMoney from '../../functions/toMoney';
import toLocaleCurrency from '../../functions/toLocaleCurrency';
import checkIsNumber from '../../functions/checkIsNumber';
import dateIsValid from '../../functions/dateIsValid';

import AuthContext from '../../contexts/auth';

import {
  newCustomer,
  newPaymentProfile,
  newInvoice,
  deleteInvoice,
} from '../../services/vindi';

import api from '../../services/api';

import 'react-credit-cards/es/styles-compiled.css';
import './style.css';

function Checkout(props) {
  const selected = props.location.state ? props.location.state.selected : [];
  const cnpj = props.location.state ? props.location.state.cnpj : null;
  const companies = JSON.parse(
    sessionStorage.getItem('@portal-cliente/companies'),
  );
  const token = JSON.parse(sessionStorage.getItem('@portal-cliente/token'));
  const { user } = useContext(AuthContext);
  const device = {
    marca: '',
    modelo: '',
    so: osName,
    so_versao: osVersion,
  };
  const [card, setCard] = useState({
    cvc: '',
    expiry: '',
    focus: '',
    name: '',
    number: '',
  });
  const [parcels, setParcels] = useState([]);
  const [payment, setPayment] = useState({
    parcels: 1,
  });
  const [error, setError] = useState({
    exist: false,
    number: false,
    name: false,
    expiry: false,
    cvc: false,
    parcels: false,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [vindi, setVindi] = useState({});
  const [totalSelected, setTotalSelected] = useState(0);

  const handleChangePayment = e => {
    const p = {
      keys: selected.map(item => item.chave),
      parcels: parseInt(e.target.value) + 1,
      value: parseFloat(parcels[e.target.value].price.replace(',', '.')),
    };

    setPayment({ ...payment, ...p });
  };

  const handleInputFocus = e => {
    setCard({ ...card, focus: e.target.name });
  };

  const handleChange = e => {
    const { name, value } = e.target;

    setCard({ ...card, [name]: value });
  };

  const handlePay = async () => {
    // valida os dados do cartão
    const newError = {};
    if (
      !card.number ||
      card.number === '' ||
      card.number.replace(/[^0-9]/g, '').length < 16
    )
      newError.number = true;
    if (!card.name || card.name === '' || checkIsNumber(card.name))
      newError.name = true;
    if (!card.expiry || card.expiry === '' || card.expiry === '00/00')
      newError.expiry = true;
    if (card.expiry !== '') {
      const isValid = await dateIsValid(card.expiry);
      if (!isValid) newError.expiry = true;
    }
    if (!card.cvc || card.cvc === '' || card.cvc === 123 || card.cvc.length < 3)
      newError.cvc = true;

    if (newError.number || newError.name || newError.expiry || newError.cvc) {
      newError.exist = true;
      return setError(newError);
    } else {
      setError({
        exist: false,
        number: false,
        name: false,
        expiry: false,
        cvc: false,
        parcels: false,
      });
    }

    try {
      setLoading(true);
      setMessage('Criando perfil do cliente.');

      // efetua o pagamento na vindi

      // cria usuário na vindi
      const customer = await newCustomer({
        name: user.name,
        street: user.address,
        number: user.number || 0,
        zipcode: user.zipcode,
        neighborhood: user.neighborhood,
        city: user.city,
        state: user.state,
        phone: user.cell,
        phone_type: 'mobile',
        key_vindi: vindi.key,
        cpf: user.cpf,
        additional_details: user.adjunct,
        token,
      });
      // cria perfil de pagamento
      setMessage('Criando perfil de pagamento do cliente');
      const profile = await newPaymentProfile({
        holder_name: card.name,
        card_expiration: card.expiry,
        card_number: card.number,
        card_cvv: card.cvc,
        payment_method_code: 'credit_card',
        customer_id: customer,
        key_vindi: vindi.key,
        token,
      });
      // cria a invoice
      setMessage('Criando fatura do cliente.');
      const invoice = await newInvoice({
        customer_id: customer,
        payment_method_code: 'credit_card',
        installments: payment.parcels,
        product_id: parseInt(vindi.product),
        amount: toLocaleCurrency(totalSelected),
        key_vindi: vindi.key,
        token,
      });
      if (
        invoice &&
        (!invoice.charges[0].last_transaction ||
          invoice.charges[0].last_transaction.status !== 'success')
      ) {
        await deleteInvoice(invoice.id, key_vindi, token);
        setLoading(false);
        return Alert.alert(
          'FALHA',
          'Não foi possível realizar a cobrança no cartão de crédito',
        );
      }
      if (invoice && invoice.status === 'pending') {
        await deleteInvoice(invoice.id, key_vindi, token);
        setLoading(false);
        return Alert.alert(
          'FALHA',
          'Não foi possível realizar a cobrança no cartão de crédito',
        );
      }
      let aut = '';
      if (
        'authorizationCode' in
        invoice.charges[0].last_transaction.gateway_response_fields
      ) {
        aut =
          invoice.charges[0].last_transaction.gateway_response_fields
            .authorizationCode;
      } else if (
        'stone_id_rcpt_tx_id' in
        invoice.charges[0].last_transaction.gateway_response_fields
      ) {
        aut =
          invoice.charges[0].last_transaction.gateway_response_fields
            .stone_id_rcpt_tx_id;
      }
      let nsu = '';
      if (
        'nsu' in invoice.charges[0].last_transaction.gateway_response_fields
      ) {
        nsu = invoice.charges[0].last_transaction.gateway_response_fields.nsu;
      } else if (
        'proof_of_sale' in
        invoice.charges[0].last_transaction.gateway_response_fields
      ) {
        nsu =
          invoice.charges[0].last_transaction.gateway_response_fields
            .proof_of_sale;
      } else if (
        'stone_id_rcpt_tx_id' in
        invoice.charges[0].last_transaction.gateway_response_fields
      ) {
        nsu =
          invoice.charges[0].last_transaction.gateway_response_fields
            .stone_id_rcpt_tx_id;
      }
      const gateway = invoice.charges[0].last_transaction.gateway.id;
      const bandeira =
        invoice.charges[0].last_transaction.payment_profile.payment_company
          .code;
      // monta dados do pagamento
      setMessage('Montando dados do pagamento.');
      // monta a baixa
      const bills = selected.map(item => {
        const vencimento = `${item.date.split('/')[2]}${
          item.date.split('/')[1]
        }${item.date.split('/')[0]}`;
        return {
          cliente: user.name,
          contrato: item.contrato,
          chave: item.chave,
          nsu,
          aut,
          gateway,
          bandeira,
          valor_desconto: 0,
          valor_juros: 0,
          valor_multa: 0,
          valor_titulo: toLocaleCurrency(item.price),
          data_baixa: new Date(),
          vencimento,
        };
      });

      const p = {
        baixas: bills,
        id_app: CryptoJS.MD5(
          user.cpf + Date.now(),
          'secret key virtuscobranca',
        ).toString(),
        contrato: bills[0].contrato,
        cnpj,
        cgc_cliente: user.cpf,
        cliente: user.name,
        mensagem_contrato: '',
        quantidade_parcelas: payment.parcels || 1,
        dt_reagendamento: '',
        forma_pagamento: 'CC',
        cobrador: '',
        status: 'realizado',
        dt_agenda: '',
        dt_cobranca: new Date(),
        total: bills[0].valor_titulo,
        payment_profile: profile.id,
        mot_reprovador: '',
        origin: 'portal',
        mot_reagendamento: '',
        date_to: '',
        lat: 0,
        lng: 0,
        device,
      };

      // salva pagamento
      setMessage('Salvando dados do pagamento.');
      await api.post('/charges/payment/portal/create', p, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      setMessage(null);

      // redireciona para pagamentos
      return props.history.push({
        pathname: '/pagamentos',
      });
    } catch (e) {
      setMessage('Falha ao tentar realizar pagamento.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selected.length === 0) {
      return props.history.push({
        pathname: '/dashboard',
        state: {
          selected,
        },
      });
    } else {
      const total = selected.reduce(
        (total, value) => total + toLocaleCurrency(value.price),
        0,
      );
      const p = selected.map((item, index) => {
        const parcel = index + 1;
        const price = toMoney(total / parcel);
        return {
          parcel,
          key: item.chave,
          price,
        };
      });
      setParcels(p);
    }
  }, []);

  useEffect(() => {
    const company = companies.filter(item => item.cnpj === cnpj);
    setVindi(company[0].vindi);
  }, []);

  useEffect(() => {
    if (selected.length > 0) {
      let total = 0;
      selected.forEach(item => {
        const sum = toLocaleCurrency(item.price);
        total = total + sum;
      });
      setTotalSelected(toMoney(total));
    } else {
      setTotalSelected(0);
    }
  }, [selected]);

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8">
          <div className="my-1 p-3 bg-body rounded shadow-sm">
            <h6 className="titulo-card pb-2 mb-0">Dados do pagamento</h6>
            <div className="">
              <div className="container preload">
                <div className="creditcard">
                  <Cards
                    cvc={card.cvc}
                    expiry={card.expiry}
                    focused={card.focus}
                    name={card.name}
                    number={card.number}
                    placeholders={{ name: 'NOME NO CARTAO' }}
                    locale={{ valid: 'validade' }}
                  />
                </div>
              </div>
              {error.exist && (
                <div className="alinhamento-label-cartao">
                  <div className="d-flex flex-column">
                    <div className="alert alert-danger" role="alert">
                      Os dados do cartão devem ser preenchidos corretamente!
                    </div>
                  </div>
                </div>
              )}
              <div className="alinhamento-label-cartao">
                <div className="d-flex flex-column">
                  <div className="colunas-form-pagamento-cartao">
                    <label>Número do cartão</label>
                    <InputMask
                      type="text"
                      name="number"
                      id="number"
                      mask="9999 9999 9999 9999"
                      onChange={handleChange}
                      className={`input-cartao-pagamento ${
                        error.number && 'border border-danger'
                      }`}
                      value={card.number}
                      placeholder="Número do cartão"
                      onFocus={handleInputFocus}
                      style={{ textTransform: 'uppercase' }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <div className="colunas-form-pagamento-cartao has-validation">
                    <label>Titular do cartão</label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className={`input-cartao-pagamento ${
                        error.name && 'border border-danger'
                      }`}
                      placeholder="Nome conforme o cartão"
                      value={card.name}
                      onChange={handleChange}
                      onFocus={handleInputFocus}
                      style={{ textTransform: 'uppercase' }}
                    />
                    {/* {error.name && (
                      <span className="help-block">
                        Campo obrigatório
                      </span>
                    )} */}
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div style={{ width: '51%' }} className="d-flex">
                    <div className="colunas-form-pagamento-cartao">
                      <label>Validade</label>
                      <InputMask
                        type="text"
                        name="expiry"
                        id="expiry"
                        mask="99/9999"
                        onChange={handleChange}
                        className={`input-cartao-pagamento ${
                          error.expiry && 'border border-danger'
                        }`}
                        value={card.expiry}
                        placeholder="Validade"
                        onFocus={handleInputFocus}
                        style={{ textTransform: 'uppercase' }}
                      />
                      {/* {error.expiry && (
                        <span className="help-block">
                          Campo obrigatório
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div style={{ width: '29%' }} className="d-flex">
                    <div className="colunas-form-pagamento-cartao">
                      <label>CVV</label>
                      <InputMask
                        type="text"
                        name="cvc"
                        id="cvc"
                        mask="999"
                        onChange={handleChange}
                        className={`input-cartao-pagamento ${
                          error.cvc && 'border border-danger'
                        }`}
                        value={card.cvc}
                        placeholder="CVV"
                        onFocus={handleInputFocus}
                        style={{ textTransform: 'uppercase' }}
                      />
                      {/* {error.cvv && (
                        <span className="help-block">
                          O códito de segurança deve ser informado corretamente
                        </span>
                      )} */}
                    </div>
                  </div>
                  <div style={{ width: '34%' }} className="d-flex">
                    <div className="colunas-form-pagamento-cartao">
                      <label>Parcelas</label>
                      <select
                        className="form-select input-cartao-pagamento "
                        onChange={handleChangePayment}
                      >
                        {parcels.length > 0 &&
                          parcels.map((item, index) => (
                            <option
                              defaultValue={index === 0}
                              key={index}
                              value={index}
                            >
                              {item.parcel} x R$ {item.price}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
                {message && (
                  <div
                    className="row justify-content-center align-items-center"
                    style={{ maxWidth: '100%', padding: 10, margin: 'auto' }}
                  >
                    <span className="badge bg-warning text-dark">
                      {message}
                    </span>
                  </div>
                )}
              </div>

              <div className="alinhamento-label-cartao">
                <div className="d-flex justify-content-center button-pay">
                  <button
                    className="btn btn-primary btn-primary-pay btn-lg"
                    type="submit"
                    style={{ width: '100%' }}
                    onClick={handlePay}
                    disabled={loading}
                  >
                    Pagar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Summary selected={selected} />
      </div>
    </div>
  );
}

export default Checkout;
