export default function dateIsValid (date) {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();

    const monthInput = parseInt(date.split('/')[0].slice(0, 2));
    const yearInput = parseInt(
      `${parseInt(year.toString().slice(0, 2))}${parseInt(
        date.split('/')[1],
      )}`,
    );

    if (monthInput > 12) {
      return false;
    }

    if ((monthInput < month && yearInput <= year) || yearInput < year) {
      return false;
    }

    return true;
  };