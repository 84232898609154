import React, { useContext, useState } from 'react';

import AuthContext from '../../../contexts/auth';
import LayoutContext from '../../../contexts/layout';

import FormConfirmCell from './FormConfirmCell';
import FormCheckUser from './FormCheckUser';
import ConfirmPhone from './ConfirmPhone';
import FormRegisterPass from './FormRegisterPass';

function FirstAccess() {
  const {
    firstAccess,
    loading,
    user,
    userRegister,
    codeGenerator,
    validateCodeRecovery,
    createNewUser,
  } = useContext(AuthContext);
  const { primaryColor, logoDefault } = useContext(LayoutContext);

  const [confirm, setConfirm] = useState(false);
  const [createPass, setCreatePass] = useState(false);
  const [cpf, setCpf] = useState(user ? user.cpf : null);

  const handleChangeRegister = async (user, type) => {
    const result = await codeGenerator(user, type);
    if (result.ok) {
      setConfirm(true);
    }
  };

  const handleSubmitConfirm = async code => {
    const cpfEdit = cpf.replace(/[^0-9]/gi, '');

    const result = await validateCodeRecovery(code, cpfEdit, 'firstaccess');

    if (result.ok) {
      setCreatePass(true);
    }
  };

  const handleSubmitPass = async password => {
    const cpfEdit = cpf.replace(/[^0-9]/gi, '');

    createNewUser(cpfEdit, password);
  };

  const renderMessage = () => {
    const title = confirm
      ? 'Confirme seu número'
      : user && user.nome
      ? 'Finalizar cadastro'
      : 'Novo por aqui?';
    const msg = confirm
      ? 'Insira o código que enviamos por SMS para seu celular cadastrado, pode levar alguns segundos'
      : user && user.nome
      ? 'Complete as informações e ative seu cadastro para utilizar o painel do cliente'
      : 'Antes de realizar seu cadastro precisamos confirmar seu número de celular, caso tenha mudado atualize agora.';

    return (
      <>
        <h4 className="font-size-30 mt-4">{title}</h4>
        <p className="text-muted">{msg}</p>
      </>
    );
  };

  return (
    <div className="col-lg-7">
      <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
        <div className="w-100">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div>
                <div>
                  <a href="/" className="logo">
                    <img src={logoDefault} height="80" alt="logo" />
                  </a>
                </div>
                {renderMessage()}
                <div className="">
                  {createPass ? (
                    <FormRegisterPass
                      loading={loading}
                      user={user}
                      primaryColor={primaryColor}
                      onChange={handleSubmitPass}
                    />
                  ) : confirm ? (
                    <ConfirmPhone
                      loading={loading}
                      user={user}
                      userRegister={userRegister}
                      primaryColor={primaryColor}
                      onChange={handleSubmitConfirm}
                    />
                  ) : user && user.name ? (
                    <FormConfirmCell
                      user={user}
                      loading={loading}
                      codeGenerator={codeGenerator}
                      primaryColor={primaryColor}
                      onChange={handleChangeRegister}
                    />
                  ) : (
                    <FormCheckUser
                      loading={loading}
                      firstAccess={firstAccess}
                      primaryColor={primaryColor}
                      onChange={setCpf}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FirstAccess;
