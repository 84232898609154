import React, { createContext, useState, useEffect } from 'react';
import {
  isMobile,
  osName,
  osVersion,
  browserName,
  deviceType,
  mobileModel,
  mobileVendor,
  browserVersion,
} from 'react-device-detect';

import api from '../services/api';

import { errorAlert } from '../components/Toast';

const AuthContext = createContext({
  signed: Boolean,
  user: Object,
  loading: Boolean,
  code: Boolean,
  device: Object,
  token: String,
});

export const AuthProvider = ({ children }) => {
  const user = JSON.parse(sessionStorage.getItem('@portal-cliente/user'));
  const token = JSON.parse(sessionStorage.getItem('@portal-cliente/token'));
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState(false);
  const [device, setDevice] = useState({});
  const [codeValidation, setCodeValidation] = useState(0);

  async function signIn({ username, password }) {
    try {
      setLoading(true);
      const { data } = await api.post('/customer/portal/session', {
        username,
        password,
        device,
      });

      window.location.reload();

      sessionStorage.setItem('@portal-cliente/user', JSON.stringify(data.user));
      sessionStorage.setItem(
        '@portal-cliente/group',
        JSON.stringify(data.group),
      );
      sessionStorage.setItem(
        '@portal-cliente/companies',
        JSON.stringify(data.companies),
      );
      sessionStorage.setItem(
        '@portal-cliente/contracts',
        JSON.stringify(data.contracts),
      );
      sessionStorage.setItem(
        '@portal-cliente/contract_terms',
        JSON.stringify(data.contract_terms),
      );
      return sessionStorage.setItem(
        '@portal-cliente/token',
        JSON.stringify(data.token),
      );
    } catch (e) {
      let msg = 'Falha ao logar';
      if (
        e.response &&
        e.response.data &&
        e.response.data.code === 'user_not_found'
      ) {
        msg =
          'Usuário não encontrato. Se esse for seu primeiro acesso, tente criar sua conta antes de logar.';
      }
      if (
        e.response &&
        e.response.data &&
        e.response.data.code === 'password_does_not_match'
      ) {
        msg =
          'Senha incorreta!! Caso tenha esquecido sua senha basta clicar em "Esqueceu a senha?"';
      }
      if (
        e.response &&
        e.response.data &&
        e.response.data.code === 'user_not_activated'
      ) {
        msg =
          'Usuário não ativado. Para maiores informações entre em contato pelos nossos telefones de atendimento.';
      }
      return errorAlert(msg);
    } finally {
      setLoading(false);
    }
  }

  async function signOut(logout) {
    if (logout) {
      sessionStorage.removeItem('@portal-cliente/user');
      sessionStorage.removeItem('@portal-cliente/companies');
      sessionStorage.removeItem('@portal-cliente/contracts');
      sessionStorage.removeItem('@portal-cliente/contract_terms');
      return sessionStorage.removeItem('@portal-cliente/token');
    }
  }

  async function firstAccess(cpf) {
    try {
      setLoading(true);
      // deve checar se usuário já existe antes de buscar os dados
      const { data } = await api.post('/customer/portal/validator', {
        username: cpf,
        device,
      });

      if (data.exist) {
        return errorAlert(
          'Usuário já existe. Se você esqueceu a senha, acesse a área de recuperação de senha',
        );
      }

      return sessionStorage.setItem(
        '@portal-cliente/user',
        JSON.stringify(data.user),
      );
    } catch (e) {
      let msg = 'Falha ao validar usuário';
      if (
        e.response &&
        e.response.data &&
        e.response.data.code === 'incomplete_cpf'
      ) {
        msg = 'CPF incompleto!';
      }
      if (
        e.response &&
        e.response.data &&
        e.response.data.code === 'user_not_activated'
      ) {
        msg =
          'Usuário não ativo. Para mais informações entre em contato pelos nossos telefones de atendimento ao cliente.';
      }
      if (
        e.response &&
        e.response.data &&
        e.response.data.code === 'customer_not_found'
      ) {
        msg =
          'Cliente não encontrado. Para mais informações entre em contato pelos nossos telefones de atendimento ao cliente.';
      }
      return errorAlert(msg);
    } finally {
      setLoading(false);
    }
  }

  async function codeGenerator(user, type) {
    setLoading(true);
    const userForGenerator = {
      name: user.nome,
      cell: user.celular.replace(/[^0-9]+/g, ''),
      cpf: user.cgc_cliente,
      type,
      device,
    };

    try {
      const { data } = await api.post(
        `/customer/portal/verificationcode`,
        userForGenerator,
      );
      return { ok: true };
    } catch (e) {
      errorAlert('Não é possivel realizar a validação através de SMS');
      return { error: true };
    } finally {
      setLoading(false);
    }
  }

  async function userRegister(code) {
    try {
      setLoading(true);
      await api.get(`/number-validation/${code}/${user.cgc_cliente}/index`);
      const userForSaved = {
        nome: user.nome
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase(),
        cgc_cliente: user.cgc_cliente.replace(/[^0-9]+/g, ''),
        email: user.email.toUpperCase(),
        password: user.password,
        confirmPassword: user.confirmPassword,
        celular: user.celular.replace(/[^0-9]+/g, ''),
        cep: user.cep.replace(/[^0-9]+/g, ''),
        endereco: user.endereco
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase(),
        complemento: user.complemento
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase(),
        bairro: user.bairro
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase(),
        municipio: user.municipio
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toUpperCase(),
        estado: user.estado,
      };
      await api.post('/portal/cliente', userForSaved);
      signIn({
        cgc_cliente: userForSaved.cgc_cliente,
        password: userForSaved.password,
      });
    } catch (e) {
      let msg = 'Falha ao validar usuário';
      if (
        e.response &&
        e.response.data &&
        e.response.data.code === 'invalid_code'
      ) {
        msg = 'O código digitado não é válido. Tente novamente';
      }
      if (
        e.response &&
        e.response.data &&
        e.response.data.code === 'expired_code'
      ) {
        msg =
          'O código expirou, necessário realizar todo o procedimento novamente';
      }
      return errorAlert(msg);
    } finally {
      setLoading(false);
    }
  }

  async function recoveryPassword(cpf, cell) {
    try {
      setLoading(true);
      const { data } = await api.post(`/customer/portal/verificationcode`, {
        cpf,
        cell,
        type: 'recovery',
        device,
      });
      return { ok: true };
    } catch (err) {
      if (
        err.response.data &&
        err.response.data.code === 'cell_does_not_match'
      ) {
        errorAlert(
          'O celular informado não confere com o cadastrado, por favor informe o celular corretamente.',
        );
      } else {
        errorAlert('Não é possivel realizar a validação através de SMS');
      }

      return { error: true };
    } finally {
      setLoading(false);
    }
  }

  async function validateCodeRecovery(code, cpf, type) {
    try {
      setCodeValidation(code);
      setLoading(true);
      const { data } = await api.get(
        `/customer/portal/verificationcode/${type}/${code}/${cpf}`,
      );

      if (data.code === 'invalid_code') return errorAlert('Código inválido');
      if (data.coode === 'expired_code')
        return errorAlert('O código expirou, tente novamente');

      return { ok: true, user: data.user };
    } catch (e) {
      let msg = 'Falha ao validar usuário';
      if (
        e.response &&
        e.response.data &&
        e.response.data.code === 'invalid_code'
      ) {
        msg = 'O código digitado não é válido. Tente novamente';
      }
      if (
        e.response &&
        e.response.data &&
        e.response.data.code === 'expired_code'
      ) {
        msg =
          'O código expirou, necessário realizar todo o procedimento novamente';
      }
      errorAlert(msg);
      return { ok: false };
    } finally {
      setLoading(false);
    }
  }

  async function saveNewPassword(username, new_password) {
    try {
      setLoading(true);
      const { data } = await api.post('/customer/portal/recovery', {
        username,
        new_password,
        code: codeValidation,
        device,
      });

      sessionStorage.setItem('@portal-cliente/user', JSON.stringify(data.user));
      sessionStorage.setItem(
        '@portal-cliente/companies',
        JSON.stringify(data.companies),
      );
      sessionStorage.setItem(
        '@portal-cliente/contracts',
        JSON.stringify(data.contracts),
      );
      sessionStorage.setItem(
        '@portal-cliente/contract_terms',
        JSON.stringify(data.contract_terms),
      );
      return sessionStorage.setItem(
        '@portal-cliente/token',
        JSON.stringify(data.token),
      );
    } catch (e) {
      let msg = 'Falha ao salvar a senha';
      if (
        e.response &&
        e.response.data &&
        e.response.data.code === 'invalid_code'
      ) {
        msg = 'Código digitado está inválido!';
      }
      return errorAlert(msg);
    } finally {
      setLoading(false);
    }
  }

  async function createNewUser(username, password) {
    try {
      setLoading(true);
      const { data } = await api.post('/customer/portal/user/create', {
        username,
        password,
        device,
      });

      sessionStorage.setItem('@portal-cliente/user', JSON.stringify(data.user));
      sessionStorage.setItem(
        '@portal-cliente/group',
        JSON.stringify(data.group),
      );
      sessionStorage.setItem(
        '@portal-cliente/companies',
        JSON.stringify(data.companies),
      );
      sessionStorage.setItem(
        '@portal-cliente/contracts',
        JSON.stringify(data.contracts),
      );
      sessionStorage.setItem(
        '@portal-cliente/contract_terms',
        JSON.stringify(data.contract_terms),
      );
      return sessionStorage.setItem(
        '@portal-cliente/token',
        JSON.stringify(data.token),
      );
    } catch (e) {
      return errorAlert('Falha ao cadastrar novo usuário');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    setDevice({
      type: deviceType,
      mobile: isMobile,
      brand: mobileVendor,
      model: mobileModel,
      os: osName,
      os_version: osVersion,
      browser_name: browserName,
      browser_version: browserVersion,
    });

    window.addEventListener(
      'unload',
      () => {
        signOut();
      },
      false,
    );
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signed: !!token,
        user,
        token,
        firstAccess,
        signIn,
        signOut,
        loading,
        userRegister,
        codeGenerator,
        code,
        recoveryPassword,
        validateCodeRecovery,
        saveNewPassword,
        device,
        createNewUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
