import React, { useState, useContext, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { errorAlert } from '../../../components/Toast';

function FormCheckUser({ firstAccess, loading, primaryColor, onChange }) {

  const [data, setData] = useState({
    cgc_cliente: '',
  });
  const [error, setError] = useState(null);

  const handleChangeData = event => {
    setData({ ...data, [event.target.id]: event.target.value });
    onChange(event.target.value)
  };

  const handleChangeFirstAccess = async event => {
    event.preventDefault();

    if (data.cgc_cliente === '') {
      return errorAlert('CPF do Titular deve ser preenchido corretamente');
    }

    const cpf = data.cgc_cliente.replace(/[^0-9]/gi,'')

    // busca o cliente
    await firstAccess(cpf);
  };

  return (
    <form className="form-horizontal" onSubmit={handleChangeFirstAccess}>
      <div className="form-group auth-form-group-custom mb-4">
        <label htmlFor="username">CPF do titular</label>
        <InputMask
          type="text"
          name="cgc_cliente"
          id="cgc_cliente"
          mask="999.999.999-99"
          onChange={handleChangeData}
          className="form-control"
          value={data.cgc_cliente}
          placeholder="Digite o CPF"
        />
        {error && <span>{error}</span>}
      </div>
      <div className="mt-4 text-center">
        <a
          href="/"
          style={{
            padding: 13,
            fontSize: 18,
            fontWeight: 600,
            float: 'left',
            width: '45%',
            paddingLeft: 0,
          }}
          className="btn btn-outline-primary"
        >
          <i className="ri-arrow-left-s-line align-middle ml-2"></i>
          <span
            style={{
              paddingTop: 1,
              fontSize: 18,
              display: 'inline-block',
            }}
          >
            Voltar
          </span>
        </a>
        <button
          style={{
            padding: 13,
            fontSize: 18,
            fontWeight: 600,
            float: 'right',
            width: '45%',
            backgroundColor: primaryColor,
            filter: loading ? 'opacity(30%)' : '',
          }}
          className="btn btn-primary"
          type="submit"
          disabled={loading}
        >
          {loading ? 'Carregando...' : 'Cadastrar'}
        </button>
      </div>
    </form>
  );
}

export default FormCheckUser;
