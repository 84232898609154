import React, { useRef } from 'react';

function FirstAccess({ loading, primaryColor, onChange }) {
  const inputOne = useRef();
  const inputTwo = useRef();
  const inputThree = useRef();
  const inputFour = useRef();

  const handleRegister = async () => {
    onChange(
      `${inputOne.current.value}${inputTwo.current.value}${inputThree.current.value}${inputFour.current.value}`
    );
  }

  return (
    <div className="form-horizontal">
      <div className="form-group auth-form-group-custom mb-4">
        <div className="vcode" id="vcode">
          <input
            ref={inputOne}
            type="phone"
            className="vcode-input"
            maxLength="1"
            id="vcode1"
            onChange={() => inputTwo.current.focus()}
          />
          <input
            ref={inputTwo}
            type="phone"
            className="vcode-input"
            maxLength="1"
            onChange={() => inputThree.current.focus()}
          />
          <input
            ref={inputThree}
            type="phone"
            className="vcode-input"
            maxLength="1"
            onChange={() => inputFour.current.focus()}
          />
          <input
            ref={inputFour}
            type="phone"
            className="vcode-input"
            maxLength="1"
            onChange={() => inputOne.current.focus()}
          />
        </div>
      </div>

      <div className="mt-4 text-center">
        <a
          href="/"
          style={{
            padding: 13,
            fontSize: 18,
            fontWeight: 600,
            float: 'left',
            width: '45%',
            paddingLeft: 0,
          }}
          className="btn btn-outline-primary"
          onClick={() => sessionStorage.removeItem('@portal-cliente/user')}
        >
          <i className="ri-arrow-left-s-line align-middle ml-2"></i>
          <span
            style={{ paddingTop: 1, fontSize: 18, display: 'inline-block' }}
          >
            Voltar
          </span>
        </a>
        <button
          style={{
            padding: 13,
            fontSize: 18,
            fontWeight: 600,
            float: 'right',
            width: '45%',
            backgroundColor: primaryColor,
            filter: loading ? 'opacity(30%)' : '',
          }}
          className="btn btn-primary"
          type="submit"
          disabled={loading}
          onClick={handleRegister}
        >
          {loading ? 'Carregando...' : 'Cadastrar'}
        </button>
      </div>
    </div>
  );
}

export default FirstAccess;
