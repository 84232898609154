import React, { useState, useEffect, useContext } from 'react';
import Skeleton from 'react-loading-skeleton';
import { format, parseISO } from 'date-fns/';
import ptBr from 'date-fns/locale/pt-BR';

import AuthContext from '../../contexts/auth';

import { errorAlert, warningAlert } from '../../components/Toast';

import toMoney from '../../functions/toMoney';

import api from '../../services/api';

function Payments(props) {
  const { user } = useContext(AuthContext);
  const group = JSON.parse(sessionStorage.getItem('@portal-cliente/group'));
  const token = JSON.parse(sessionStorage.getItem('@portal-cliente/token'));
  const [loading, setLoading] = useState(true);
  const [payments, setPayments] = useState([]);
  const [allPayments, setAllPayments] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState({
    new: false,
    oldOne: false,
  });

  const handleChagePage = way => {
    if (way === 'prev' && page > 1) setPage(page - 1);
    if (way === 'next' && page < payments.length) setPage(page + 1);
    if (typeof way === 'number') setPage(way);
  };

  const handleChangeDetail = async data => {
    return props.history.push({
      pathname: '/detail',
      state: {
        data,
      },
    });
  };

  const mountPagination = async (data, max) => {
    const chargesPagination = [[]];
    let index = 0;

    for (let i = 0; i < data.length; i++) {
      if (chargesPagination[index] === undefined) {
        chargesPagination[index] = [];
      }

      chargesPagination[index].push(data[i]);

      if ((i + 1) % max === 0) {
        index = index + 1;
      }
    }

    return chargesPagination;
  };

  const handleGetPayments = async () => {
    try {
      const { data } = await api.get(`/charges/${group}/${user.cpf}/list`, {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      if (data && data.contratos.length === 0) {
        warningAlert('Você não possui mais pagamentos!!');
        return setPage(1);
      }

      // monta os titulos pagos
      const finished = [];
      data.contratos.forEach(elem => {
        elem.titulos_pagos.forEach(item => {
          finished.push({
            key: item.chave.trim(),
            payment_method: '',
            value: toMoney(parseInt(item.valor_pago)),
            status: 'processed',
            date: format(
              new Date(
                item.data_pagamento.split('-')[0],
                parseInt(item.data_pagamento.split('-')[1]) - 1,
                item.data_pagamento.split('-')[2],
              ),
              'dd/MM/yyyy',
            ),
          });
        });
      });

      // monta os titulos em processamento
      const inProcess = [];
      data.processando.forEach(item => {
        const [{ cnpj }] = data.contratos.filter(
          elem => elem.codigo === item.contrato,
        );

        inProcess.push({
          key: item.chave.trim(),
          payment_method: '',
          value: toMoney(item.valor_titulo),
          date: format(new Date(parseISO(item.data_baixa)), 'dd/MM/yyyy'),
          hour: format(new Date(parseISO(item.data_baixa)), 'h:mm'),
          detail: true,
          card: item.bandeira,
          nsu: item.nsu,
          payment_date: format(
            parseISO(item.data_baixa),
            "dd 'de' MMMM', 'yyyy",
            { locale: ptBr },
          ),
          due_date: '',
          // due_date: `${item.vencimento.slice(6, 8)}/${item.vencimento.slice(
          //   4,
          //   6,
          // )}/${item.vencimento.slice(0, 4)}`,
          payments: item,
          cnpj,
        });
      });

      const p = finished.concat(inProcess);

      const pSort = p.sort((a, b) => {
        return (
          new Date(
            b.date.split('/')[2],
            parseInt(b.date.split('/')[1]) - 1,
            b.date.split('/')[0],
          ) -
          new Date(
            a.date.split('/')[2],
            parseInt(a.date.split('/')[1]) - 1,
            a.date.split('/')[0],
          )
        );
      });
      setAllPayments(pSort);

      const pPagination = await mountPagination(pSort, 10);

      setPayments(pPagination);
    } catch (e) {
      errorAlert('Falha ao carregar titulos pagos!');
    } finally {
      setLoading(false);
    }
  };

  const handleFilter = async type => {
    if (type === 'new') {
      setFilter({ new: !filter.new, oldOne: false });
      const paymentsFilter = allPayments.sort((a, b) => {
        return (
          new Date(
            b.date.split('/')[2],
            parseInt(b.date.split('/')[1]) - 1,
            b.date.split('/')[0],
          ) -
          new Date(
            a.date.split('/')[2],
            parseInt(a.date.split('/')[1]) - 1,
            a.date.split('/')[0],
          )
        );
      });
      const pPagination = await mountPagination(paymentsFilter, 10);
      setPayments(pPagination);
    }
    if (type === 'oldOne') {
      setFilter({ oldOne: !filter.oldOne, new: false });
      const paymentsFilter = allPayments.sort((a, b) => {
        return (
          new Date(
            a.date.split('/')[2],
            parseInt(a.date.split('/')[1]) - 1,
            a.date.split('/')[0],
          ) -
          new Date(
            b.date.split('/')[2],
            parseInt(b.date.split('/')[1]) - 1,
            b.date.split('/')[0],
          )
        );
      });
      const pPagination = await mountPagination(paymentsFilter, 10);
      setPayments(pPagination);
    }
  };

  const renderLoading = () => (
    <div className="col-md-12">
      <div className="my-1 p-3 bg-body rounded shadow-sm">
        <div>
          <div className="titulo-elementos d-flex bd-highlight">
            <div className="d-flex flex-row">
              <div className="flex-grow-1 bd-highlight">
                <h6 className="titulo-card">
                  Histórico de títulos pagos pagamentos
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <Skeleton count={6} height={62} />
        </div>
      </div>
    </div>
  );

  const renderPayments = () => (
    <div className="col-md-12">
      <div className="my-1 p-3 bg-body rounded shadow-sm">
        <div>
          <div className="titulo-elementos d-flex bd-highlight">
            <div className="d-flex flex-row">
              <div className="flex-grow-1 bd-highlight">
                <h6 className="titulo-card">Histórico de títulos pagamentos</h6>
                <span style={{ fontSize: 12 }}>
                  Pagamentos em processamento podem levar até 72 horas
                  <br />
                  Títulos pagos em boleto ou presencialmente não são detalhados
                </span>
              </div>
            </div>
            <div className="filtrar-por-mobile d-flex ms-auto">
              <div className="filtrar-por bd-highlight">Filtrar por:</div>
              <div
                style={{ height: '2rem' }}
                className={
                  filter.new
                    ? 'btn-filtro-card-active btn-filtro-card bd-highlight'
                    : 'btn-filtro-card bd-highlight'
                }
                onClick={() => handleFilter('new')}
              >
                Mais novo
              </div>
              <div
                style={{ height: '2rem' }}
                className={
                  filter.oldOne
                    ? 'btn-filtro-card-active btn-filtro-card bd-highlight'
                    : 'btn-filtro-card bd-highlight'
                }
                onClick={() => handleFilter('oldOne')}
              >
                Mais antigo
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="titulo-tabela d-flex bd-highlight">
            <div style={{ maxWidth: '50%' }} className="flex-fill bd-highlight">
              Data
            </div>
            <div style={{ maxWidth: '30%' }} className="flex-fill bd-highlight">
              Valor
            </div>
          </div>

          {payments.length > 0 &&
            payments[page - 1].map(item => (
              <div
                className="descricao-tabela d-flex bd-highlight"
                key={item.key}
              >
                <div
                  style={{ maxWidth: '50%' }}
                  className="flex-fill bd-highlight"
                >
                  {item.date}
                  {item.hour && (
                    <div className="pills-horas" style={{ fontSize: 11 }}>
                      <i className="ri-time-line"></i> {item.hour}
                    </div>
                  )}
                </div>
                <div
                  style={{ maxWidth: '30%' }}
                  className="align-self-center flex-fill bd-highlight"
                >
                  R$ {item.value}
                </div>

                {item.detail && (
                  <div
                    style={{ maxWidth: '20%' }}
                    className="detalhar-desktop flex-fill bd-highlight font-size-12"
                  >
                    <div
                      className="btn-outline-padrao"
                      onClick={() => handleChangeDetail(item)}
                    >
                      Detalhar <i className="ri-arrow-right-s-line"></i>
                    </div>
                  </div>
                )}
                {item.detail && (
                  <div className="detalhar-mobile dropdown">
                    <button
                      style={{ fontSize: 25, backgroundColor: '#f5f5f5' }}
                      className="btn  dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      onClick={() => handleChangeDetail(item)}
                    >
                      <i className="ri-more-2-fill"></i>
                    </button>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Detalhar
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className="parent">
          <div className="left"></div>
          <div className="right">
            <div className="d-flex" style={{ alignItems: 'center' }}>
              <i
                className="seta-paginacao align-self-center ri-arrow-left-s-line"
                onClick={() => handleChagePage('prev')}
              ></i>
              {payments.map((pages, key) => (
                <div
                  className="paginacao-lista"
                  style={{
                    marginRight: payments.length - 1 === key ? 0 : '0.2rem',
                    backgroundColor: page === key + 1 ? '#8253c6' : '',
                    color: page === key + 1 ? '#fff' : '',
                    fontSize: 14,
                    padding: 0,
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                    height: '2.4rem',
                    width: '2.4rem',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleChagePage(key + 1)}
                >
                  {key + 1}
                </div>
              ))}
              <i
                className="seta-paginacao align-self-center ri-arrow-right-s-line"
                onClick={() => handleChagePage('next')}
              ></i>
            </div>
          </div>
          <div className="align-self-center">
            <div className="texto-contagem-rodape-lista">
              Exibindo {payments.length ? payments[page - 1].length : 0}{' '}
              pagamentos
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const renderBlank = () => (
    <div className="col-md-12">
      <div className="my-1 p-3 bg-body rounded shadow-sm">
        <div>
          <div className="titulo-elementos d-flex bd-highlight">
            <div className="d-flex flex-row">
              <div className="flex-grow-1 bd-highlight">
                <h6 className="titulo-card">Histórico de pagamentos</h6>
                <span style={{ fontSize: 12 }}>
                  Pagamentos em processamento podem levar até 72 horas
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div className="titulo-tabela d-flex bd-highlight">
            <div style={{ maxWidth: '50%' }} className="flex-fill bd-highlight">
              Data
            </div>
            <div style={{ maxWidth: '30%' }} className="flex-fill bd-highlight">
              Valor
            </div>
          </div>
          <div className="descricao-tabela d-flex bd-highlight">
            <div
              className="alert alert-warning"
              role="alert"
              style={{ width: '100%' }}
            >
              Você não possui pagamentos.
            </div>
          </div>
        </div>
        <div className="parent">
          <div className="left"></div>
          <div className="right">
            <div className="d-flex" style={{ alignItems: 'center' }}>
              <i
                className="seta-paginacao align-self-center ri-arrow-left-s-line"
                onClick={() => handleChagePage('prev')}
              ></i>
              <div className="paginacao-lista">1</div>
              <div
                className="paginacao-lista"
                style={{
                  backgroundColor: '#8253c6',
                  color: '#fff',
                  fontSize: 14,
                  padding: 0,
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  height: '2.4rem',
                  width: '2.4rem',
                }}
              >
                1
              </div>
              <i
                className="seta-paginacao align-self-center ri-arrow-right-s-line"
                onClick={() => handleChagePage('next')}
              ></i>
            </div>
          </div>
          <div className="align-self-center">
            <div className="texto-contagem-rodape-lista">
              Exibindo 0 pagamentos
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    handleGetPayments();
  }, [page]);

  return (
    <div
      className="tab-pane fade show active"
      id="pagamentos"
      role="tabpanel"
      aria-labelledby="pagamentos-tab"
    >
      <div className="container-fluid">
        <div className="row">
          {loading && renderLoading()}
          {!loading && payments.length > 0 && renderPayments()}
          {!loading && payments.length === 0 && renderBlank()}
        </div>
      </div>
    </div>
  );
}

export default Payments;
