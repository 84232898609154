import React, { useRef, useState, useEffect } from 'react';

function Recovery({ onChange, loading, primaryColor, onResendCode }) {
  const inputOne = useRef();
  const inputTwo = useRef();
  const inputThree = useRef();
  const inputFour = useRef();

  const [timerResendCode, setTimerResendCode] = useState(120);

  const activateTimerResendCode = () => {
    const interval = setInterval(() => {
      if (timerResendCode > 0) {
        setTimerResendCode(timer => timer - 1);
      } else {
        clearInterval(interval);
        setTimerResendCode(() => 120);
      }
    }, 1000);
  };

  const handleResendCode = () => {
    activateTimerResendCode();
    onResendCode();
  };

  const handleSubmit = async () => {
    if (timerResendCode <= 0) {
      handleResendCode();
    } else {
      onChange(
        `${inputOne.current.value}${inputTwo.current.value}${inputThree.current.value}${inputFour.current.value}`,
      );
    }
  };

  useEffect(() => {
    activateTimerResendCode();
  }, []);

  return (
    <>
      <h4 className="font-size-30 mt-4">Confirme seu número</h4>
      <p className="text-muted">Insira o código que enviamos por SMS para seu celular cadastrado, pode levar alguns segundos</p>
      <div className="">
        <div className="form-horizontal">
          <div className="form-group auth-form-group-custom mb-4">
            <div className="vcode" id="vcode">
              <input
                ref={inputOne}
                type="phone"
                className="vcode-input"
                maxLength="1"
                id="vcode1"
                onChange={() => inputTwo.current.focus()}
              />
              <input
                ref={inputTwo}
                type="phone"
                className="vcode-input"
                maxLength="1"
                onChange={() => inputThree.current.focus()}
              />
              <input
                ref={inputThree}
                type="phone"
                className="vcode-input"
                maxLength="1"
                onChange={() => inputFour.current.focus()}
              />
              <input
                ref={inputFour}
                type="phone"
                className="vcode-input"
                maxLength="1"
                onChange={() => inputOne.current.focus()}
              />
            </div>
          </div>

          {timerResendCode <= 0 && (
            <strong
              className="text-muted"
              style={{ cursor: 'pointer' }}
              onClick={handleResendCode}
            >
              Reenviar código de verificação
            </strong>
            )}
          {timerResendCode > 0 && (
            <p className="text-muted" style={{ cursor: 'pointer' }}>
              {`Reenviar código de verificação em `}
              <strong>{timerResendCode}</strong>
            </p>
          )}

          <div className="mt-4 text-center">
            <a
              href="/"
              style={{
                padding: 13,
                fontSize: 18,
                fontWeight: 600,
                float: 'left',
                width: '45%',
                paddingLeft: 0,
              }}
              className="btn btn-outline-primary"
            >
              <i className="ri-arrow-left-s-line align-middle ml-2"></i>
              <span
                style={{ paddingTop: 1, fontSize: 18, display: 'inline-block' }}
              >
                Voltar
              </span>
            </a>
            <button
              style={{
                padding: 13,
                fontSize: 18,
                fontWeight: 600,
                float: 'right',
                width: '45%',
                backgroundColor: primaryColor,
                filter: loading ? 'opacity(30%)' : '',
              }}
              className="btn btn-primary"
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? 'Carregando...' : timerResendCode <= 0 ? 'Reenviar código' : 'Cadastrar'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Recovery;
