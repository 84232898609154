import React, { useState } from 'react';
import InputMask from 'react-input-mask';

function Recovery({ onChange, loading, primaryColor }) {
  const [data, setData] = useState({
    cgc_cliente: '',
    phone: '',
  });

  const handleChange = event => {
    setData({ ...data, [event.target.id]: event.target.value });
  };

  const handleSubmit = async () => {
    onChange(data);
  };

  return (
    <>
      <h4 className="font-size-30 mt-4">Recuperar senha</h4>
      <p className="text-muted">
        Insira seu CPF e Telefone para recuperar a senha.
      </p>
      <div className="">
        <div className="form-horizontal">
          <div className="form-group auth-form-group-custom mb-4">
            <label htmlFor="username">CPF do titular</label>
            <InputMask
              type="text"
              name="cgc_cliente"
              id="cgc_cliente"
              mask="999.999.999-99"
              onChange={handleChange}
              className="form-control"
              value={data.cgc_cliente}
              placeholder="Digite o CPF"
            />
          </div>
          <div className="form-group auth-form-group-custom mb-4">
            <label htmlFor="username">Celular</label>
            <InputMask
              type="text"
              name="phone"
              id="phone"
              mask="(99) 99999-9999"
              onChange={handleChange}
              className="form-control"
              value={data.phone}
              placeholder="(00) 00000-0000"
            />
          </div>

          <div className="mt-4 text-center">
            <a
              href="/"
              style={{
                padding: 13,
                fontSize: 18,
                fontWeight: 600,
                float: 'left',
                width: '45%',
                paddingLeft: 0,
              }}
              className="btn btn-outline-primary"
            >
              <i className="ri-arrow-left-s-line align-middle ml-2"></i>
              <span
                style={{ paddingTop: 1, fontSize: 18, display: 'inline-block' }}
              >
                Voltar
              </span>
            </a>
            <button
              style={{
                padding: 13,
                fontSize: 18,
                fontWeight: 600,
                float: 'right',
                width: '45%',
                backgroundColor: primaryColor,
                filter: loading ? 'opacity(30%)' : '',
              }}
              className="btn btn-primary"
              type="submit"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? 'Carregando...' : 'Recuperar Senha'}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Recovery;
