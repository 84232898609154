import React, { useState, useContext, useEffect } from 'react';
import { Formik } from 'formik';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import AuthContext from '../../contexts/auth';

import api from '../../services/api';

import { errorAlert, successAlert } from '../../components/Toast';

import ufs from '../../services/ufs.json';

const schema = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string().required('Campo obrigatório'),
  email: Yup.string().email('Formato inválido'),
  cell: Yup.string().test('cell', 'Celular inválido', () => {
    return !(
      cell.value.replace(/[^0-9]/gi, '').length < 11 || cell.value[5] !== '9'
    );
  }),
  phone: Yup.string().test('phone', 'Telefone inválido', () => {
    return !(
      phone.value.replace(/[^0-9]/gi, '').length < 10 &&
      phone.value.replace(/[^0-9]/gi, '').length > 0
    );
  }),
  zipcode: Yup.string().test('zipcode', 'CEP inválido', () => {
    return !(zipcode.value.replace(/[^0-9]/gi, '').length < 8);
  }),
  address: Yup.string(),
  adjunct: Yup.string(),
  city: Yup.string(),
  state: Yup.string(),
});

function Profile() {
  const { user, primaryColor, device } = useContext(AuthContext);
  const token = JSON.parse(sessionStorage.getItem('@portal-cliente/token'));
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [uf, setUf] = useState(null);
  const [locale, setLocale] = useState(null);

  const handleChange = event => {
    let value;
    if (event.target.id === 'email') {
      value = event.target.value;
    } else {
      value = event.target.value.replace(/[^a-z0-9 ]/gi, '');
    }
    if (event.target.id === 'cell' || event.target.id === 'phone')
      value = value.replace(' ', '');
    if (value !== '') setUserData({ ...userData, [event.target.id]: value });
    else {
      const newUser = { ...userData };
      delete newUser[event.target.id];
      setUserData(newUser);
    }
  };

  const handleSubmitUser = async () => {
    setLoading(true);

    if (!userData || Object.keys(userData).length === 0)
      return setLoading(false);

    const newUser = {
      ...userData,
      device,
      version: '2.0.0',
    };

    // coloca todos os valores em uppercase
    const uc = c =>
        c >= 'a' && c <= 'z' ? String.fromCharCode(c.charCodeAt() - 32) : c,
      toUpperCase = (key, value) =>
        typeof value === 'string' ? Array.from(value, uc).join('') : value;

    const newUserEdited = JSON.parse(JSON.stringify(newUser), toUpperCase);

    try {
      const { data } = await api.put(
        `/customer/portal/${user._id}/update`,
        newUserEdited,
        {
          headers: {
            Authorization: 'Bearer ' + token,
          },
        },
      );

      const userForSave = { ...user, ...newUserEdited };

      if (data.code === 'updated') {
        sessionStorage.setItem(
          '@portal-cliente/user',
          JSON.stringify(userForSave),
        );
        return successAlert('Usuário atualizado com sucesso!');
      }
    } catch (e) {
      const msg =
        e.response.data.code === 'user_pending_update'
          ? 'Você possui alteração pendente de aprovação, necessário aguardar para fazer novas modificações'
          : 'Falha ao atualizar dados do perfil';

      return errorAlert(msg);
    } finally {
      return setLoading(false);
    }
  };

  const getCities = async () => {
    const userUf = uf || user.state;
    const selectedUf = ufs.filter(item => item.abbreviation === userUf);
    const { data } = await api.get(`/uf/${selectedUf[0].id_uf}`, {
      headers: {
        Authorization: 'Bearer ' + token,
      },
    });
    setLocale(data);
  };

  useEffect(() => {
    getCities();
  }, [uf]);

  return (
    <div
      className="tab-pane fade show active"
      id="usuario"
      role="tabpanel"
      aria-labelledby="usuario-tab"
    >
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="my-1 p-3 bg-body rounded shadow-sm">
              <h6 className="titulo-card pb-2 mb-0">Dados do usuário</h6>
              <div
                className="alinhamento-formulario-nome-e-senha"
                style={{ paddingBottom: 50 }}
              >
                <Formik
                  initialValues={user}
                  enableReinitialize={true}
                  onSubmit={handleSubmitUser}
                  validationSchema={schema}
                >
                  {props => {
                    const {
                      values,
                      touched,
                      errors,
                      handleBlur,
                      handleSubmit,
                    } = props;

                    return (
                      <form className="form-horizontal" onSubmit={handleSubmit}>
                        <div className="form-group auth-form-group-custom mb-4">
                          <label htmlFor="name">Nome</label>
                          <input
                            type="text"
                            id="name"
                            placeholder="Digite seu nome"
                            value={userData ? userData.name : values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.name && touched.name
                                ? 'form-control text-input error'
                                : 'form-control text-input'
                            }
                            disabled
                          />
                        </div>
                        <div className="form-group auth-form-group-custom mb-4">
                          <label htmlFor="email">E-mail</label>
                          <input
                            type="mail"
                            id="email"
                            placeholder="Digite seu e-mail"
                            value={userData ? userData.email : values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.email && touched.email
                                ? 'form-control text-input error'
                                : 'form-control text-input'
                            }
                          />
                        </div>
                        <div className="form-group auth-form-group-custom mb-4">
                          <label htmlFor="cell">Celular</label>
                          <InputMask
                            id="cell"
                            placeholder="Digite o celular"
                            type="text"
                            mask="(99) 99999-9999"
                            value={userData ? userData.cell : values.cell}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.cell && touched.cell
                                ? 'form-control text-input error'
                                : 'form-control text-input'
                            }
                          />
                        </div>
                        <div className="form-group auth-form-group-custom mb-4">
                          <label htmlFor="phone">Telefone</label>
                          <InputMask
                            id="phone"
                            placeholder="Digite o telefone"
                            type="text"
                            mask="(99) 9999-9999"
                            value={userData ? userData.phone : values.phone}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.phone && touched.phone
                                ? 'form-control text-input error'
                                : 'form-control text-input'
                            }
                          />
                        </div>
                        <div className="form-group auth-form-group-custom mb-4">
                          <label htmlFor="cpf">CPF</label>
                          <InputMask
                            type="text"
                            id="cpf"
                            mask="999.999.999-99"
                            placeholder="Digite seu cpf"
                            value={userData ? userData.cpf : values.cpf}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.cpf && touched.cpf
                                ? 'form-control text-input error'
                                : 'form-control text-input'
                            }
                            disabled
                          />
                        </div>
                        <div
                          className="form-group auth-form-group-custom mb-4"
                          style={{ width: '48%', display: 'inline-flex' }}
                        >
                          <label htmlFor="zipcode">CEP</label>
                          <InputMask
                            type="text"
                            id="zipcode"
                            mask="99999-999"
                            placeholder="Digite seu cep"
                            value={userData ? userData.zipcode : values.zipcode}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.zipcode && touched.zipcode
                                ? 'form-control text-input error'
                                : 'form-control text-input'
                            }
                          />
                        </div>
                        <div
                          style={{
                            width: '48%',
                            display: 'inline-flex',
                            float: 'right',
                          }}
                          className="form-group auth-form-group-custom mb-4"
                        >
                          <label htmlFor="address">Endereço</label>
                          <input
                            type="text"
                            id="address"
                            placeholder="Digite seu endereço"
                            value={userData ? userData.address : values.address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.address && touched.address
                                ? 'form-control text-input error'
                                : 'form-control text-input'
                            }
                          />
                        </div>
                        <div className="form-group auth-form-group-custom mb-4">
                          <label htmlFor="adjunct">Complemento</label>
                          <input
                            type="text"
                            id="adjunct"
                            placeholder="Digite o complemento"
                            value={userData ? userData.adjunct : values.adjunct}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.adjunct && touched.adjunct
                                ? 'form-control text-input error'
                                : 'form-control text-input'
                            }
                          />
                        </div>
                        <div className="form-group auth-form-group-custom mb-4">
                          <label htmlFor="neighborhood">Bairro</label>
                          <input
                            type="text"
                            id="neighborhood"
                            placeholder="Digite seu bairro"
                            value={
                              userData
                                ? userData.neighborhood
                                : values.neighborhood
                            }
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={
                              errors.neighborhood && touched.neighborhood
                                ? 'form-control text-input error'
                                : 'form-control text-input'
                            }
                          />
                        </div>
                        <div
                          style={{
                            width: '48%',
                            display: 'inline-flex',
                          }}
                          className="form-group auth-form-group-custom mb-4"
                        >
                          <label htmlFor="state">Estado</label>
                          <select
                            type="text"
                            id="state"
                            placeholder="Selecione seu estado"
                            value={userData ? userData.state : values.state}
                            onChange={e => {
                              handleChange(e), setUf(e.target.value);
                            }}
                            onBlur={handleBlur}
                            className="form-select form-control text-input"
                          >
                            {ufs.map(u => {
                              return (
                                <option key={u.id_uf} value={u.abbreviation}>
                                  {u.abbreviation}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div
                          style={{
                            width: '48%',
                            display: 'inline-flex',
                            float: 'right',
                          }}
                          className="form-group auth-form-group-custom mb-4"
                        >
                          <label htmlFor="city">Cidade</label>
                          <select
                            type="text"
                            id="city"
                            placeholder="Selecione sua cidade"
                            value={userData ? userData.city : values.city}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-select form-control text-input"
                          >
                            {locale &&
                              locale.map(l => {
                                return (
                                  <option
                                    key={l._id}
                                    value={l.name_without_accent}
                                  >
                                    {l.name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                        <button
                          style={{
                            padding: 13,
                            fontSize: 18,
                            fontWeight: 600,
                            float: 'right',
                            width: '100%',
                            backgroundColor: primaryColor,
                            filter: loading ? 'opacity(30%)' : '',
                          }}
                          className="btn btn-primary"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? 'Carregando...' : 'Salvar'}
                        </button>
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
